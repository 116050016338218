<template>
  <transition
    enter-class="tw-opacity-0 tw-max-height-0"
    enter-active-class="tw-transition-all tw-duration-300"
    leave-active-class="tw-transition-all tw-duration-300"
    leave-to-class="tw-opacity-0 tw-max-height-0"
  >
    <div
      v-show="errors[0] && show"
      :id="id"
      class="errors tw-relative tw-text-s tw-rounded-md tw-mt-1"
      :class="{
        'errors--is-warning tw-bg-form-warning-bg tw-arrow-after-warning': errorAsWarning,
        'errors--is-error tw-bg-form-error-bg tw-arrow-after-error': isError
      }"
    >
      <span
        v-if="isError"
        aria-hidden
        class="icon tw-text-white tw-block tw-absolute tw-w-2 tw-h-2 tw-flex tw-items-center tw-justify-center"
      >
        <span class="tw-relative">!</span>
      </span>

      <span v-if="isError && !errorAsWarning">
        <strong>Fehler</strong>: {{ errors[0] }}
      </span>
      <span v-else-if="isWarning && errorAsWarning">
        <strong>Warnung</strong>: Bitte überprüfen
      </span>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    errors: Array,
    show: Boolean,
    id: String,
    errorAsWarning: Boolean
  },
  computed: {
    isWarning() {
      return this.errors[0] && this.errors[0] === "_";
    },
    isError() {
      return this.errors[0] && this.errors[0] !== "_";
    }
  }
};
</script>

<style lang="scss" scoped>
.errors {
  /* TODO: We might have to increase or calculate this value */
  max-height: 200px;

  .icon {
    top: 8px;
    right: 8px;

    > span {
      top: -1px;
    }

    &:before {
      content: "";
      @apply tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-rounded-full tw-bg-form-error;
    }
  }

  &.errors--is-error {
    padding: 10px 30px 10px 12px;
  }

  &.errors--is-warning {
    padding: 10px 30px 10px 12px;

    .icon {
      &:before {
        @apply tw-bg-form-warning;
      }
    }
  }
}
</style>
