<template>
  <label
    @click="toggle"
    class="tw-inline-block tw-p-1 text-s tw-rounded-md tw-relative"
    :class="classes"
  >
    <span
      href="#"
      v-if="$slots.toggle"
      class="tw-icon tw-icon-hinzufuegen-bold tw-absolute tw-top-0 tw-right-0 tw-transform tw-transition-transform tw-duration-300 tw-text-l"
      :class="{ 'tw-rotate-45': isOpen }"
    />

    <slot :open="isOpen" />

    <span
      v-if="$slots.toggle"
      :open="isOpen"
      :aria-expanded="isOpen"
      :class="{ 'tw-hidden': !isOpen }"
    >
      <slot name="toggle"></slot>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    direction: {
      type: [Object, String],
      validator: direction => {
        if (direction === null) {
          return true;
        }

        const allowedDirections = [
          "top-left",
          "top-right",
          "bottom-left",
          "bottom-right",
          "left"
        ];

        if (typeof direction === "object" && direction !== null) {
          return Object.values(direction).every(direction => {
            return allowedDirections.includes(direction);
          });
        } else {
          return allowedDirections.includes(direction);
        }
      }
    },
    emphasize: Boolean,
    info: {
      type: Boolean,
      default: false
    },
    open: Boolean,
    bold: { type: Boolean, default: () => true }
  },
  data() {
    return {
      isOpen: this.$props.open
    };
  },
  computed: {
    closeable() {
      return !!this.$slots.toggle;
    },
    classes() {
      const cls = [];

      // Responsive direction config
      // e.g. via
      // <tipp :direction="{'': 'top-left', 'sm': 'bottom-right'} />"
      if (typeof this.direction === "object" && this.direction !== null) {
        Object.entries(this.direction).forEach(([breakpoint, direction]) => {
          if (breakpoint === "" || breakpoint == "default") {
            cls.push(`tw-arrow-after-tipp-${direction}`);
          } else {
            cls.push(`${breakpoint}:tw-arrow-after-tipp-${direction}`);
          }
        });
      } else if (this.direction) {
        // simple config
        cls.push(`tw-arrow-after-tipp-${this.direction}`);
      }

      if (this.info) {
        cls.push("tw-bg-lightBlue-100", "tw-text-grey-800", "tw-info");
      } else if (this.emphasize) {
        cls.push("tw-bg-orange-500", "tw-text-white", "tw-emphasized");
      } else {
        cls.push("tw-bg-orange-100", "tw-text-orange-500");
      }

      if (this.bold) {
        cls.push("font-bold");
      }

      if (this.closeable) {
        cls.push("tw-pr-3", "tw-cursor-pointer");
      }

      return cls;
    }
  },
  methods: {
    toggle($event) {
      // Anchors inside the tipp just work like they are supposed to
      if ($event.target.tagName === "A") {
        return;
      }

      $event.preventDefault();
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style lang="css" scoped>
.tw-emphasized:after {
  @apply tw-bg-orange-500;
}
.tw-info:after {
  @apply tw-bg-lightBlue-100;
}
</style>
