<template>
  <accordion
    @open="$emit('open')"
    @close="$emit('close')"
    @toggled="$emit('toggled', $event)"
    toggle-classes="tw-absolute tw-top-0 tw-right-0"
  >
    <template #button-content="{focus, hovered}">
      <span
        class="tw-icon
                 tw-icon-information-bold
                 tw-text-xl
                 tw-border
                 tw-rounded-full
                 tw-border-solid"
        :class="[focus || hovered ? focusButtonClasses : null, borderColor]"
      >
      </span>
    </template>

    <template #default>
      <div
        class="tw-hidden sm:tw-block"
        :class="contentClasses"
        v-html="content"
      ></div>
      <div
        class="sm:tw-hidden"
        :class="contentClasses"
        v-html="contentMobile"
      ></div>
    </template>
  </accordion>
</template>

<script>
import Accordion from "./Accordion";

export default {
  components: { Accordion },
  props: {
    content: String,
    contentMobile: String,
    contentClasses: [String, Array, Object],
    focusButtonClasses: {
      type: [String, Array, Object],
      default: "tw-bg-grey-800 tw-text-white"
    },
    borderColor: {
      type: String,
      default: "tw-border-grey-800"
    }
  }
};
</script>

<style lang="scss" scoped></style>
