<template>
  <div>
    <h2 class="tw-text-base lg:tw-text-xl tw-text-grey-800 tw-mb-1">
      <slot name="headline" />
    </h2>

    <p v-if="$slots.subline">
      <slot name="subline" />
    </p>

    <slot name="form" />
  </div>
</template>

<script>
export default {
  props: {
    config: Object
  }
};
</script>

<style scoped></style>
