import { render, staticRenderFns } from "./FixedDigit.vue?vue&type=template&id=612ae82b&scoped=true&"
import script from "./FixedDigit.vue?vue&type=script&lang=js&"
export * from "./FixedDigit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "612ae82b",
  null
  
)

export default component.exports