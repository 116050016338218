<template>
  <div>
    <h2 class="tw-mb-1 tw-text-base lg:tw-text-xl tw-text-grey-800">
      <slot name="headline" />
    </h2>

    <Dropdown
      class="lg:tw-w-1/2"
      :label="dropdownLabel"
      :disabled="!data.availablePlayingDates.length"
      @change="$emit('change', $event.target.value)"
    >
      <option disabled v-if="!data.availablePlayingDates.length" selected>
        Daten werden abgerufen...
      </option>
      <option
        v-for="date in data.availablePlayingDates"
        :key="date.date"
        :value="date.date"
        :selected="date.date === data.playingDate"
        >{{ date.label }}</option
      >
    </Dropdown>
  </div>
</template>

<script>
import { getPlayingStartDates } from "@/services/playing-start-dates-service";
import Dropdown from "@/components/Dropdown";
import { PlayingDateUnspecified } from "@/constants";

export default {
  components: { Dropdown },
  props: {
    dropdownLabel: String
  },
  data() {
    return { dates: [] };
  },
  async created() {
    const result = await getPlayingStartDates(
      this.data.product,
      this.data.shippingDate,
      this.data.playingDate
    );
    if (result.success) {
      this.data.availablePlayingDates = result.data.map(date => ({
        date,
        label: this.$formatDate(date, "de-DE")
      }));

      this.data.playingDate = this.data.availablePlayingDates[0].date;
    }

    if (this.data.userSelectedPlayingDate) {
      this.data.playingDate = this.data.userSelectedPlayingDate;
    } else {
      this.data.playingDate = this.data.availablePlayingDates[0].date;
    }
  },
  watch: {
    "data.shippingDate": async function newShippingDate(newShippingDate) {
      const result = await getPlayingStartDates(
        this.data.product,
        newShippingDate,
        this.data.userSelectedPlayingDate || this.data.playingDate
      );

      if (result.success) {
        this.data.availablePlayingDates = result.data.map(date => ({
          date,
          label: this.$formatDate(date, "de-DE")
        }));

        if (
          !this.data.availablePlayingDates.find(
            ({ date }) =>
              date === this.data.playingDate ||
              date === this.data.userSelectedPlayingDate
          )
        ) {
          this.data.playingDate = this.userSelectedPlayingDate = this.data.availablePlayingDates[0].date;
        }
      }
    }
  },
  beforeDestroy() {
    this.data.playingDate = PlayingDateUnspecified;
  }
};
</script>

<style scoped></style>
