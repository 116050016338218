<template>
  <label class="tw-relative tw-block tw-pt-1">
    <div>{{ label }}{{ asterisk }}</div>
    <div class="tw-relative tw-h-input tw-w-auto tw-mt-1">
      <select
        :disabled="disabled"
        @change="$emit('change', $event)"
        class="tw-w-full
               tw-h-full
               tw-w-auto
               tw-relative
               tw-flex
               tw-items-center
               tw-h-full
               tw-border
               tw-border-grey-800
               tw-rounded-none
               tw-appearance-none
               font-bold
               tw-px-2
               tw-pr-4
               focus:tw-border-2"
      >
        <slot />
      </select>

      <span
        class="tw-icon
               tw-pointer-events-none
               tw-icon-weiter-down-bold
               tw-absolute
               tw-top-1/2
               tw-transform
               tw--translate-y-1/2
               tw-right-0
               tw-mr-1
               tw-text-l"
      ></span>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    label: String,
    rules: [Array, Object, String]
  },
  computed: {
    asterisk() {
      return this.rules && this.rules.indexOf("required") > -1 ? "*" : "";
    }
  }
};
</script>

<style lang="scss" scoped></style>
