<template>
  <li
    tabindex="0"
    class="
      drawings__slider__slide
      tw-cursor-pointer tw-text-center tw-p-1 tw-rounded-md
      lg:tw-mx-1
      md:tw-flex
      tw-items-center tw-justify-center tw-flex tw-relative tw-mb-3
    "
    v-if="isVisible()"
    :data-qa="dataQa"
    :class="drawingClasses"
    @click="toggleChecked(drawing)"
    @keydown.space.prevent="toggleChecked(drawing)"
  >
    <div
      class="
        drawings__level
        tw-text-base
        md:tw-text-m
        xl:tw-text-base
        font-bold
        tw-cursor-pointer tw-w-full tw-px-1 tw-pb-2 tw-pt-1
      "
    >
      <template v-if="winningPlanExpanded">
        <span
          class="drawing__winningOptions"
          v-for="(rank, index) in this.drawingLevel.ranks.slice(1).reverse()"
          :key="index"
        >
          <template v-if="rank.oneTimeWinningSum > 0">
            <p class="tw-pb-1/2">
              <strong
                >{{ $formatPrice(rank.oneTimeWinningSum, true, true) }}
                <abbr title="Euro">€</abbr></strong
              >
            </p>
            <p class="tw-pb-1 tw-text-s">
              <span v-html="winningPlanTextReplaced(rank)"></span>
            </p>
            <hr
              class="tw-mb-1 tw-border-t-0"
              :class="
                drawingLevel.isSelected
                  ? 'tw-border-white tw-opacity-80'
                  : 'tw-border-grey-300'
              "
            />
          </template>
          <template v-if="rank.monthlyWinningSum > 0">
            <p class="tw-pb-1/2">
              <strong
                >{{ $formatPrice(rank.monthlyWinningSum, true, true) }}
                <abbr title="Euro">€</abbr></strong
              >
            </p>
            <p class="tw-pb-1 tw-text-s">
              <span>{{ rank.winningPlanText }}</span>
            </p>
            <hr
              class="tw-mb-1 tw-border-t-0"
              :class="
                drawingLevel.isSelected
                  ? 'tw-border-white tw-opacity-80'
                  : 'tw-border-grey-300'
              "
            />
          </template>
        </span>
      </template>
      <span
        v-if="drawingLevel.oneTimeWinningSum > 0"
        :data-qa="`${this.drawingLevel.productMatrixAttribute}_${this.drawingLevel.productMatrixValue}`"
        >{{ $formatPrice(drawingLevel.oneTimeWinningSum, false) }}
        <abbr title="Euro" role="presentation">€</abbr></span
      >
      <span
        v-if="drawingLevel.monthlyWinningSum > 0"
        class="tw-block lg:tw-text-s xl:tw-text-base"
        ><span v-if="drawingLevel.oneTimeWinningSum > 0">+</span>
        {{ $formatPrice(drawingLevel.monthlyWinningSum, false) }}
        <abbr title="Euro">€</abbr> mtl.</span
      >
      <!--  Add last winninplan information if plan expanded -->
      <p v-if="winningPlanExpanded" class="tw-pb-1 tw-text-s">
        <span v-html="winningPlanTextReplaced(drawingLevel.ranks[0])"></span>
      </p>
    </div>
    <p
      class="
        drawings__level__price
        tw-absolute tw-text-s
        font-bold
        tw-mt-2 tw-text-grey-800
      "
    >
      <span
        >{{ $formatPrice(drawingLevel.price) }}
        <abbr title="Euro" role="presentation">€&nbsp;</abbr></span
      >
      <br v-if="jahreslosData" />
      <span v-if="priceText" v-html="priceText" />
    </p>
    <select-indicator
      class="
        drawings__level__indicator
        tw-absolute
        tw-bottom-0
        tw-ml-0
        tw-left-1/2
        tw-left-0
        tw-transform
        tw--translate-x-1/2
      "
      :selected="drawingLevel.isSelected"
    />
  </li>
</template>

<script>
import SelectIndicator from "../SelectIndicator";

export default {
  name: "DrawingItem",
  components: { SelectIndicator },
  props: [
    "drawing",
    "drawingLevel",
    "drawingIndex",
    "priceText",
    "winningPlanExpanded",
    "bestsellerActive",
  ],
  computed: {
    drawingClasses: function () {
      return [
        this.drawingLevel.isSelected
          ? `is-checked tw-border-${this.primaryColor}-500 active:tw-border-${this.primaryColor}-500 tw-bg-${this.primaryColor}-500 tw-text-white hover:tw-bg-${this.primaryColor}-500 hover:tw-border-${this.primaryColor}-500 tw-border-2`
          : `tw-bg-white hover:tw-text-grey-800 tw-border-${this.primaryColor}-500 hover:tw-bg-${this.primaryColor}-100 focus:tw-bg-${this.primaryColor}-100 focus:tw-text-grey-800 focus:tw-border-${this.primaryColor}-500 tw-border-2`,
        { "tw-mb-8": this.jahreslosData },
      ];
    },
    dataQa: function () {
      return (
        "InputContainer_" +
        this.drawingLevel.productMatrixAttribute +
        "_" +
        this.drawingLevel.productMatrixValue
      );
    },
  },
  methods: {
    isVisible() {
      if (this.data.product == 'JL' && this.drawingLevel.index == this.WinningCategoryLevelHideIndex) {
        if (this.data.editModeEnabled && this.drawingLevel.isSelected) {
          return true;
        }
        return false;
      }
     return true;
    },
    winningPlanTextReplaced(rank) {
      return rank.winningPlanText
        .replace("{endziffern}", rank.winningDigits.toString())
        .replace("{gewinner}", rank.numberOfWinners.toString());
    },
    toggleChecked: function (drawing) {
      if (this.bestsellerActive) {
        this.$emit("toggleBestseller");
      }
      if (this.drawingLevel.isSelected) {
        if (this.jahreslosData) {
          // Do not allow unselect for Jahreslos
          return;
        }

        this.drawingLevel.isSelected = !this.drawingLevel.isSelected;
        return;
      } else {
        this.drawing.levels.forEach(function (key, index) {
          if (drawing.levels[index].isSelected) {
            drawing.levels[index].isSelected =
              !drawing.levels[index].isSelected;
          }
        });

        if (this.boosterData) {
          this.trackDrawingBoosterClick(
            this.drawingLevel,
            this.drawingIndex === 1 ? "basis" : "zusatz"
          );
        } else {
          this.trackClick(
            `pk_lotterie-konfi_mitte_${this.drawingLevel.productMatrixValue}`
          );
        }
        this.drawingLevel.isSelected = !this.drawingLevel.isSelected;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.drawings__level__indicator {
  bottom: -14px;
  top: initial;
}
.drawings__slider__slide {
  @apply tw-border-2 tw-border-solid;
  &:hover,
  &:focus {
    .drawing__winningOptions hr {
      border-color: white;
    }
  }
  .drawing__winningOptions:last-child hr {
    display: none;
  }
}
</style>

<!--hasRanks() {-->
<!--return true;-->
<!--},-->
