<template>
  <div class="digit-input">
    <input
      ref="input"
      v-on="$listeners"
      inputmode="decimal"
      pattern="\d*"
      :disabled="disabled"
      @input="hideValue = false"
      @keydown.delete="
        $event.preventDefault();
        $emit('delete');
      "
      @focus="hideValue = true"
      @blur="hideValue = false"
      class="tw-w-full
             tw-h-full
             font-bold
             tw-text-center
             tw-border
             tw-border-grey-800
             tw-rounded-md
             tw-py-1
             tw-px-1/2"
      :value="hideValue ? '' : value.substring(0, 1)"
      :class="{
        'tw-cursor-wait': disabled,
        'tw-opacity-50': disabled
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    autofocus: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      hideValue: false
    };
  },
  mounted() {
    if (this.autofocus) {
      setTimeout(() => {
        this.$refs.input.focus();
      }, 50);
    }
  },
  methods: {
    setFocus() {
      this.$refs.input.focus();
    }
  }
};
</script>

<style scoped>
.digit-input {
  width: 22px;
  height: 30px;
}

input {
  font-size: 16px;
}

@screen lg {
  input {
    font-size: 23px;
  }
}
</style>
