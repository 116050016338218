<template>
  <div>
    <h2 class="tw-mb-1 tw-text-base lg:tw-text-xl tw-text-grey-800">
      <slot name="headline" />
    </h2>
    <p class="tw-mt-1/2">{{ subline }}</p>

    <InputGroup
      :options="radioOptions"
      :value="selectedGreetingVal"
      :label="label"
      :inline="true"
      input-type="radio"
      class="tw-mt-2"
      @change="
        selectedGreetingVal = $event.target.value;
        emitChange();
      "
    />

    <VideoGreetingModal
      v-if="modal"
      :content="modal.content"
      :button-accept-text="modal.activateButtonText"
      :button-refuse-text="modal.deactivateButtonText"
      :link-text="modal.linkText"
      :headline="modal.headline"
      @change-greeting-selection="
        selectedGreetingVal = $event;
        emitChange();
      "
    />

    <div class="tw-mt-2" v-if="selectedGreetingVal === 'accepted'">
      <h3 class="tw-text-base tw-text-grey-800">{{ videoHeadline }}</h3>
      <div class="tw-flex tw-mt-1 lg:tw--mx-1">
        <VideoGreetingSlider
          :activeSlideIndex="
            videos.findIndex(
              v => selectedVideo && v.videoId === selectedVideo.videoId
            )
          "
        >
          <ImageItem
            class="video-greeting__slider__slide tw-flex-shrink-0 lg:tw-flex-shrink lg:tw-w-1/3"
            :video="video"
            v-for="video in videos"
            :key="video.videoId"
            :selected="selectedVideo && selectedVideo.videoId === video.videoId"
            @click="selectVideo($event)"
            @show-video="$refs.modal.openModal(video)"
          />
        </VideoGreetingSlider>
      </div>

      <VideoGreetingPlaybackModal ref="modal" />

      <template v-if="selectedVideo">
        <h3 class="tw-text-base tw-text-grey-800 tw-mt-2">
          {{ contentHeadline }}
        </h3>

        <Input
          v-if="selectedVideo.enableRecipientTextInput"
          class="tw-w-full md:tw-w-3/4 tw-px-1/2"
          :maxLength="selectedVideo.recipientTextMaxLength"
          show-max-length
          :initialValue="selectedInputValues.recipient"
          @input="
            selectedInputValues.recipient = $event.target.value;
            emitContentChange();
          "
          :label="selectedVideo.recipientTextLabel"
        />
        <Input
          tag="textarea"
          rows="3"
          v-if="selectedVideo.enableGreetingTextInput"
          class="tw-w-full md:tw-w-3/4 tw-px-1/2"
          :maxLength="selectedVideo.greetingTextMaxLength"
          show-max-length
          :initialValue="selectedInputValues.greeting"
          @input="
            selectedInputValues.greeting = $event.target.value;
            emitContentChange();
          "
          :label="selectedVideo.greetingTextLabel"
        />
        <Input
          v-if="selectedVideo.enableSenderTextInput"
          class="tw-w-full md:tw-w-3/4 tw-px-1/2"
          :maxLength="selectedVideo.senderTextMaxLength"
          show-max-length
          :initialValue="selectedInputValues.sender"
          @input="
            selectedInputValues.sender = $event.target.value;
            emitContentChange();
          "
          :label="selectedVideo.senderTextLabel"
        />
      </template>
    </div>
  </div>
</template>

<script>
import InputGroup from "./InputGroup.vue";
import Input from "./Input.vue";
import VideoGreetingModal from "./VideoGreetingModal.vue";
import ImageItem from "./ImageItem.vue";
import VideoGreetingSlider from "./VideoGreetingSlider";
import VideoGreetingPlaybackModal from "./VideoGreetingPlaybackModal";

export default {
  name: "VideoGreetingSelector",
  components: {
    InputGroup,
    Input,
    VideoGreetingModal,
    ImageItem,
    VideoGreetingSlider,
    VideoGreetingPlaybackModal
  },
  props: {
    subline: String,
    label: String,
    modalOpenText: String,
    modal: Object,
    videos: Array,
    videoHeadline: String,
    contentHeadline: String,
    initialVideoId: String
  },
  data() {
    return {
      radioOptions: [
        { value: "accepted", name: "Ja" },
        { value: "refused", name: "Nein" }
      ],
      selectedGreetingVal: this.$root.data.videoGreeting
        ? "accepted"
        : "refused",
      selectedVideo: this.$props.videos.find(
        v => v.videoId === this.$props.initialVideoId
      ),
      inputValues: {}
    };
  },
  created() {
    this.videos.forEach(video => {
      let recipient = "";
      let greeting = "";
      let sender = "";

      if (
        this.data.videoGreeting &&
        video.videoId === this.data.videoGreeting.videoTemplateId
      ) {
        recipient = this.data.videoGreeting.receiverName;
        greeting = this.data.videoGreeting.greetingText;
        sender = this.data.videoGreeting.senderName;
      }

      this.$set(this.inputValues, video.videoId, {});
      this.$set(this.inputValues[video.videoId], "recipient", recipient);
      this.$set(this.inputValues[video.videoId], "greeting", greeting);
      this.$set(this.inputValues[video.videoId], "sender", sender);
    });
  },
  methods: {
    selectVideo(selectedVideo) {
      this.selectedVideo = selectedVideo;
      this.emitContentChange();
    },
    emitContentChange() {
      const videoId = this.selectedVideo.videoId;

      this.$emit("change", {
        videoTemplateId: videoId,
        senderName: this.inputValues[videoId].sender,
        receiverName: this.inputValues[videoId].recipient,
        greetingText: this.inputValues[videoId].greeting
      });
    },
    emitChange() {
      this.$emit(
        "change-selector",
        this.selectedGreetingVal === "accepted" ? true : null
      );

      if (!this.selectedVideo || this.selectedGreetingVal !== "accepted") {
        this.$emit("change", null);
        return;
      }

      const videoId = this.selectedVideo.videoId;

      this.$emit("change", {
        videoTemplateId: videoId,
        senderName: this.inputValues[videoId].sender,
        receiverName: this.inputValues[videoId].recipient,
        greetingText: this.inputValues[videoId].greeting
      });
    }
  },
  computed: {
    selectedInputValues() {
      return this.inputValues[this.selectedVideo.videoId];
    }
  }
};
</script>
