/**
 * @typedef {Object} HttpResult
 * @property {boolean} success
 * @property {object} data
 * @property {status} status
 */

import { httpClient } from "@/main";
/**
 *
 * @param {"MGL" | "5SL" | "JL"} product
 * @returns {HttpResult}
 */
export async function getPlayingStartDates(
  product,
  shippingDate,
  playingStartDate
) {
  const payload = {
    product
  };

  if (shippingDate) {
    payload.ShippingDate = shippingDate;
  }

  if (playingStartDate) {
    payload.PlayingStartDate = playingStartDate;
  }

  return await httpClient.post(
    "/shop/PlayingStartDateApi/GetNextPossiblePlayingStartDates",
    payload
  );
}
