var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tw-flex md:tw-hidden"},_vm._l((_vm.tabs),function(tab,index){return _c('button',{key:index,staticClass:"tw-border-b-2 tw-border-grey-300 tw-p-2 tw-flex-grow tw-text-center font-bold focus:tw-outline-none",class:{
        'tw-border-grey-300': !_vm.isVisibleTab(index),
        'tw-border-red-500 tw-text-red-500': _vm.isVisibleTab(index)
      },on:{"click":function($event){return _vm.activateTab(index)}}},[_vm._v(" Einsatz "+_vm._s(_vm.$formatPrice(tab.price))+" € ")])}),0),_c('table',{staticClass:"tw-w-full tw-mt-2 tw-table-fixed"},[_c('thead',[_c('tr',{staticClass:"tw-bg-grey-300 tw-border-white"},[_c('th',{staticClass:"tw-px-2 tw-py-1 tw-text-left"},[_vm._v(" Endziffern ")]),_vm._l((_vm.tabs),function(tab,index){return _c('th',{key:index,staticClass:"tw-px-2 tw-py-1 tw-text-right tw-border-l-2 tw-border-white",class:{
            'tw-hidden md:tw-table-cell': !_vm.isVisibleTab(index)
          }},[_c('p',{staticClass:"tw-hidden md:tw-block"},[_vm._v(" Einsatz "+_vm._s(_vm.$formatPrice(tab.price))+" € ")]),_c('p',{staticClass:"md:tw-hidden"},[_vm._v("Gewinn")])])})],2)]),_c('tbody',_vm._l((_vm.tabs[0].ranks),function(rank,rankIndex){return _c('tr',{key:rankIndex,staticClass:"tw-bg-grey-200 tw-border-b-2 tw-border-white"},[_c('td',{staticClass:"tw-px-2 tw-py-1"},[_vm._v(_vm._s(rank.winningDigits)+" Richtige")]),_vm._l((_vm.tabs),function(tab,tabIndex){return _c('td',{key:tabIndex,staticClass:"tw-px-2 tw-py-1 tw-text-right tw-border-l-2 tw-border-white",class:{
            'tw-hidden md:tw-table-cell': !_vm.isVisibleTab(tabIndex)
          }},[(rankIndex === 0 && tabIndex === 0)?_c('span',[_vm._v(_vm._s(_vm.boosterData.basislosLowWinningPlanJackpotText)+" ")]):_vm._e(),(rankIndex === 0 && tabIndex === 1)?_c('span',[_vm._v(_vm._s(_vm.boosterData.basislosHighWinningPlanJackpotText)+" ")]):_vm._e(),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.$formatPrice(tab.ranks[rankIndex].oneTimeWinningSum))+" €")])])})],2)}),0)]),_c('div',{staticClass:"tw-mt-1 tw-text-s",domProps:{"innerHTML":_vm._s(_vm.boosterData.htmlBasislosWinningPlanLegalNotice)}})])}
var staticRenderFns = []

export { render, staticRenderFns }