<template>
  <!-- Error Message -->
  <div
    v-if="message || headline"
    class="tw-relative
            tw-mt-1
            tw-px-1
            tw-py-1
            tw-pr-5
            arrow-above-error
            tw-bg-form-error-bg
            tw-rounded-md
            tw-text-grey-800"
  >
    <span
      v-if="headline"
      class="message-headline font-bold tw-block tw-text-s"
      v-html="headline"
    />
    <span class="message-text tw-text-s" v-html="message" />

    <span
      class="message-icon
              tw-flex
              tw-justify-center
              tw-items-center
              tw-absolute
              tw-top-0
              tw-right-0
              tw-mt-1
              tw-mr-1
              tw-rounded-full
              tw-bg-form-error
              tw-text-white
              tw-icon
              tw-icon-information-bold
              tw-transform
              tw-rotate-180"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    headline: String
  }
};
</script>

<style scoped>
.message-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
}

.message-headline,
.message-text {
  font-size: 12px;
}

.arrow-above-error {
  position: relative;
}

.arrow-above-error::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  top: -4px;
  left: 8px;
  @apply tw-bg-form-error-bg;
}
</style>
