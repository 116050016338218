<template>
  <span
    class="select-indicator tw-flex tw-items-center tw-justify-center tw-bg-white tw-border-solid tw-border tw-rounded-full"
    :class="{
      'tw-bg-grey-800 tw-border-white tw-text-white': selected,
      'tw-border-grey-800': !selected
    }"
  >
    <span v-if="selected" class="tw-icon tw-icon-haken-bold" />
  </span>
</template>

<script>
export default {
  props: {
    selected: Boolean
  }
};
</script>

<style scoped>
.select-indicator {
  width: 30px;
  height: 30px;
}

.select-indicator .tw-icon {
  font-size: 28px;
}
</style>
