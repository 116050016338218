<template>
  <div class="tw-relative">
    <span class="tw-hidden" data-qa="Checkout_Configurator_Value_Lotnumber">{{
      lotnumber
    }}</span>

    <h2
      class="headline-xl tw-text-base lg:tw-text-xl font-bold tw-text-grey-800 pr-u3 pr-u0--large-up"
    >
      <span v-if="jahreslosData" class="section-index">{{ sectionIndex + 1 }}.</span>
      {{ config.headline }}
    </h2>
    <info-text
      content-classes="tw-pb-2"
      v-if="config.infoText"
      @open="trackClick('pk_click_help_losnummer-zusatzinfo')"
      @close="trackClick('pk_click_help_losnummer-zusatzinfo')"
      :content="config.infoText"
      :content-mobile="config.mobileInfoText"
    ></info-text>

    <div class="tw-flex tw-flex-wrap tw-items-center">
      <h4
        class="tw-text-s tw-mb-1/2 tw-w-full"
        v-if="state === State.EDIT"
        v-html="config.inputLabel"
      ></h4>
      <h4
        class="tw-text-s tw-mb-1/2 tw-hidden sm:tw-block tw-w-full"
        v-if="state === State.EDIT_VOUCHER_CODE"
        v-html="config.editAllDigitsLabel"
      ></h4>

      <!-- Digits -->
      <div
        class="tw-inline-flex
               font-bold
               tw-mb-1"
        data-qa="Checkout_Configurator_Value_Digits"
      >
        <!-- Fixed Digit -->
        <template v-if="state !== State.EDIT_VOUCHER_CODE">
          <fixed-digit
            v-for="(digit, index) in fixedDigits"
            :data-qa="`Checkout_Configurator_Value_FixedDigits-${index}`"
            class="tw-bg-grey-300 tw-text-grey-800"
            :key="`fixed-digit-${index}`"
            :digit="digit"
          />
        </template>

        <!-- Variable Digit -->
        <template v-if="$bp.gt('xs') && editable">
          <editable-digit
            v-for="(digit, index) in variableDigits"
            :key="`edit${index}`"
            :data-qa="`Checkout_Configurator_Value_VariableDigits-${index}`"
            :value="digit"
            :disabled="loading"
            :autofocus="index === 0"
            ref="variableDigit"
            @focus="focusInput(index)"
            @blur="blurInput(index)"
            @input="setTemporaryDigit($event.target.value, index)"
            :class="{
              'arrow-below--tiny':
                state !== State.EDIT_VOUCHER_CODE &&
                editable &&
                activeTemporaryDigitIndex === index
            }"
          />
        </template>

        <template v-if="!$bp.gt('xs') || !editable">
          <fixed-digit
            v-for="(digit, index) in variableDigits"
            :data-qa="`Checkout_Configurator_Value_FixedDigits-${index}`"
            :key="`variable-digit-${index}`"
            :digit="digit"
            class="tw-text-white"
            :class="[
              `tw-bg-${primaryColor}-500`,
              {
                'arrow-below':
                  state !== State.EDIT_VOUCHER_CODE &&
                  editable &&
                  activeTemporaryDigitIndex === index
              }
            ]"
          />
        </template>
      </div>

      <loading-spinner
        v-if="loading"
        class="tw-hidden sm:tw-inline-block tw--mt-1"
      />

      <div
        v-if="successMessage"
        data-qa="Checkout_Configurator_Value_Successmessage"
        class="success-message
               tw-flex
               sm:tw-inline-block
               tw-items-center
               tw-justify-center
               tw-w-full
               sm:tw-w-auto
               tw-py-5
               sm:tw-py-0
               font-bold
               sm:font-regular
               tw-bg-green-100
               sm:tw-bg-transparent
               xl:tw-ml-1"
      >
        <span
          class="tw-icon tw-icon-haken-bold tw-inline-block tw-align-middle"
        />
        <span class="tw-ml-1">{{ successMessage }}</span>
      </div>

      <loading-spinner v-if="loading" class="sm:tw-hidden tw--mt-1" />
    </div>

    <error-message
      data-qa="Checkout_Configurator_Value_Errormessage"
      v-if="(editable && $bp.gt('xs')) || !editable"
      :message="error"
      :headline="errorHeadline"
    />

    <!-- Mobile Digit Input -->
    <div
      class="tw-relative
             z-1
             tw-bg-grey-200
             min-width-300
             tw-mt-1
             tw-py-2
             tw-px-2
             tw-w-4/5"
      v-if="editable && !$bp.gt('xs')"
    >
      <div
        v-if="state === State.EDIT"
        class="tw-flex
              tw-relative
              tw-justify-between"
      >
        <editable-digit
          v-for="(digit, index) in temporaryDigits"
          :key="`mobile-edit${index}`"
          :data-qa="`Checkout_Configurator_Value_VariableDigits-${index}`"
          :disabled="loading"
          :value="digit"
          :autofocus="index === 0"
          ref="digit"
          @focus="focusInput(index)"
          @blur="blurInput(index)"
          @delete="clearTemporaryDigit(index)"
          @input="setTemporaryDigit($event.target.value, index)"
          :class="{
            'arrow-below--tiny': editable && activeTemporaryDigitIndex === index
          }"
        />
      </div>

      <label
        class="tw-flex tw-flex-col"
        v-if="state === State.EDIT_VOUCHER_CODE"
      >
        <span class="tw-text-s" v-html="config.editAllDigitsLabel"></span>

        <input
          :disabled="loading"
          data-qa="Checkout_Configurator_Value_PapierlosMobil"
          class="tw-mt-1"
          type="number"
          @input="setTemporaryDigits"
        />
      </label>

      <error-message :message="error" :headline="errorHeadline" />
    </div>

    <!-- Action Buttons -->
    <div class="tw-flex tw-mt-2 tw-flex-wrap" v-if="!readonly">
      <template v-if="state === State.INITIAL">
        <button-rounded
          type="rounded"
          :icon-before="true"
          :disabled="loading"
          icon="tw-icon-schreiben"
          class="action-button tw-mr-1"
          data-qa="Checkout_Configurator_Link_EditNumber"
          @click.prevent="
            trackClick('pk_click_ticket-id_edit');
            setSuccessMessage();
            setError();
            state = State.EDIT_START;
          "
        >
          <span class="tw-py-1/2" v-html="config.editNumberLinkLabel"></span>
        </button-rounded>
      </template>
      <template v-if="state === State.EDIT_START">
        <div class="tw-flex tw-flex-no-wrap">
          <button-rounded
            type="rounded"
            data-qa="Checkout_Configurator_Link_EditNumber"
            :icon-before="true"
            :disabled="loading"
            icon="tw-icon-schreiben"
            class="action-button tw-mr-1"
            @click.prevent="
              trackClick('pk_click_ticket-id_edit');
              setSuccessMessage();
              setError();
              state = State.EDIT;
            "
          >
            <span
              class="tw-py-1/2"
              v-html="config.changeNumberLinkLabel"
            ></span>
          </button-rounded>
          <button-rounded
            data-qa="Checkout_Configurator_Link_RandomNumber"
            type="rounded"
            :icon-before="true"
            :disabled="loading"
            class="action-button  tw-mr-1"
            icon="tw-icon-laden"
            @click.prevent="
              trackClick('pk_click_ticket-id_new');
              setSuccessMessage();
              generateLotnumber();
            "
          >
            <span
              class="tw-py-1/2"
              v-html="config.generateRandomLinkLabel"
            ></span>
          </button-rounded>
          <button-rounded
            v-if="product === 'MGL'"
            data-qa="Checkout_Configurator_Link_Loskarte"
            type="rounded"
            :icon-before="true"
            :disabled="loading"
            icon="tw-icon-lotterie-ziehung"
            class="action-button"
            @click.prevent="
              trackClick('pk_click_ticket-id_loskarte');
              setSuccessMessage();
              setError();
              state = State.EDIT_VOUCHER_CODE;
            "
          >
            <span
              class="tw-py-1/2"
              v-html="config.editAllDigitsLinkLabel"
            ></span>
          </button-rounded>
        </div>
      </template>

      <template v-if="state !== State.INITIAL">
        <button-rounded
          data-qa="Checkout_Configurator_Link_EditAgain"
          v-if="error && state === State.EDIT"
          type="rounded"
          :icon-before="true"
          :disabled="loading"
          icon="tw-icon-kreuz"
          class="action-button"
          @mousedown="intent = 'retry'"
          @mouseup="intent = null"
          @touchstart="intent = 'retry'"
          @touchend="intent = null"
          @click.prevent="
            setSuccessMessage();
            setError();
            clearTemporaryLotnumber();
            focusNextDigitInput(-1);
          "
        >
          <span class="tw-py-1/2" v-html="config.tryAnotherNumberLabel"></span>
        </button-rounded>

        <div class="tw-w-full tw-mt-1">
          <button-rounded
            data-qa="Checkout_Configurator_Link_CancelEdit"
            type="rounded"
            :icon-before="true"
            :disabled="loading"
            icon="tw-icon-kreuz"
            class="action-button"
            @mousedown="intent = 'cancel'"
            @mouseup="intent = null"
            @touchstart="intent = 'cancel'"
            @touchend="intent = null"
            @click.prevent="
              trackClick('pk_click_ticket-id_cancel_edit');
              setSuccessMessage();
              setError();
              cancel();
            "
          >
            <span v-html="config.cancelLabel"></span>
          </button-rounded>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import EditableDigit from "./LotnumberPicker/EditableDigit.vue";
import FixedDigit from "./LotnumberPicker/FixedDigit.vue";
import ErrorMessage from "./LotnumberPicker/ErrorMessage.vue";
import InfoText from "./InfoText.vue";
import {
  generateLotnumber,
  verifyCardLotNumber
} from "../services/lotnumber-service";
import LoadingSpinner from "./LoadingSpinner.vue";

const State = {
  INITIAL: "initial",
  EDIT_START: "edit_start",
  EDIT: "edit",
  EDIT_VOUCHER_CODE: "voucher_code"
};

const defaultLotnumber = "000000000000";

export default {
  name: "LotnumberPicker",
  components: {
    EditableDigit,
    FixedDigit,
    LoadingSpinner,
    ErrorMessage,
    InfoText
  },
  data() {
    return {
      state: State.INITIAL,
      intent: null,
      temporaryLotnumber: defaultLotnumber,
      lotnumber: this.$props.initialLotnumber,
      activeTemporaryDigitIndex: undefined,
      loading: false,
      error: null,
      errorHeadline: null,
      successMessage: null,

      // Provide `State` to the template
      State
    };
  },
  async created() {
    if (!this.readonly && this.initialLotnumber === defaultLotnumber) {
      this.loading = true;
      const result = await generateLotnumber(this.data.product);

      if (result.success) {
        this.updateLotNumber(result.data.lotNumber, "random");
      } else {
        this.setError(result.data.message, result.data.headline);
      }
      this.loading = false;
    }
  },
  props: {
    config: Object,
    readonly: Boolean,
    sectionIndex: Number,
    product: String,
    initialLotnumber: {
      type: String,
      default: () => defaultLotnumber
    },
    content: {
      type: Object,
      default: () => ({
        editNumberLinkLabel: "Wunsch&shy;nummer",
        generateRandomLinkLabel: "Zufalls&shy;nummer",
        editAllDigitsLabel: "Papier-Losnummer (Pflichtfeld)",
        editAllDigitsLinkLabel: "Papier&shy;los",
        cancelLabel: "Abbrechen",
        inputLabel: "Deine Wunschlosnummer",
        insufficientLotnumberValidationMessage:
          "Für eine Prüfung werden 6 Ziffern benötigt.",
        insufficientLotnumberValidationTitle: "Pflichtfeld",
        lotnumberUnavailableValidationMessage: "Bitte überprüfe deine Angaben",
        lotnumberUnavailableValidationTitle:
          "Diese Losnummer ist nicht verfügbar"
      })
    }
  },
  watch: {
    initialLotnumber(value) {
      this.lotnumber = value;
    }
  },
  computed: {
    fixedDigits() {
      const digits = this.editable ? this.temporaryLotnumber : this.lotnumber;

      return digits.split("").slice(0, 6);
    },
    variableDigits() {
      if (this.editable) {
        return this.temporaryDigits;
      }

      return this.lotnumber.split("").slice(6);
    },
    temporaryDigits() {
      if (this.state === State.EDIT_VOUCHER_CODE) {
        return this.temporaryLotnumber.split("");
      }

      return this.temporaryLotnumber.split("").slice(6);
    },
    editable() {
      return [State.EDIT, State.EDIT_VOUCHER_CODE].includes(this.state);
    },
    digitCount() {
      return this.temporaryLotnumber.length - 1;
    },
    digitOffset() {
      if (this.state === State.EDIT) {
        return 6;
      }

      return 0;
    }
  },
  methods: {
    async setTemporaryDigit(digit, index) {
      // Only accept single-digit integers
      const newDigit = parseInt(digit[digit.length - 1]);
      if (isNaN(newDigit)) {
        digit = 0;
      } else {
        digit = newDigit;
      }

      // Automatically jump to the next input
      if (index <= this.digitCount - this.digitOffset) {
        this.updateDigit(index + this.digitOffset, digit);
        this.focusNextDigitInput(index);
      }

      // Submit the lotnumber after entering the last digit
      if (index === this.digitCount - this.digitOffset) {
        this.performServiceCallAfterBlur(index);
      }
    },
    async setTemporaryDigits(event) {
      const input = event.currentTarget;
      const val = input.value;

      const digits = val
        .split("")
        .filter(c => /[\d]/.test(c))
        .slice(0, 12);

      for (let i = 0; i < 12; i++) {
        if (i > digits.length - 1) {
          this.updateDigit(i, 0);
        } else {
          this.updateDigit(i, parseInt(digits[i], 10) || 0);
        }
      }

      if (digits.length === 12) {
        this.performServiceCallAfterBlur(this.digitCount - this.digitOffset);
      }
    },
    async performServiceCallAfterBlur(index) {
      if (index === this.digitCount - this.digitOffset && !this.loading) {
        this.loading = true;
        let result = false;

        if (this.state === State.EDIT) {
          result = await this.generateLotnumber(this.variableDigits.join(""));
        } else if (this.state === State.EDIT_VOUCHER_CODE) {
          result = await this.verifyCardLotNumber(this.variableDigits.join(""));
        }

        // Everything worked, just show the initial state
        if (result.success) {
          this.trackClick("loskarte_richtig");
          this.state = State.INITIAL;
        } else {
          this.trackClick("loskarte_falsch");
        }

        return result;
      }

      return false;
    },
    clearTemporaryDigit(index) {
      this.updateDigit(index, 0);
      this.focusPreviousDigitInput(index);
    },
    /**
     * When digits is null, a random lotnumber is generated.
     */
    async generateLotnumber(digits = null) {
      let result = { sucess: false };
      const lotnumberType = this.state === "edit" ? "personalized" : "random";

      try {
        this.setError();
        this.setSuccessMessage();
        this.loading = true;
        result = await generateLotnumber(this.data.product, digits);

        if (!result.success) {
          this.trackClick("pk_click-ticket-id_fehler");

          this.setError(
            result.status.message,
            this.config.lotnumberUnavailableValidationTitle
          );
          return result;
        }

        this.trackClick("pk_click-ticket-id_reserviert");

        this.updateLotNumber(result.data.lotNumber, lotnumberType);

        if (lotnumberType === "random") {
          this.state = State.INITIAL;
        }

        this.setSuccessMessage(result.status.message);
        this.clearTemporaryLotnumber();
      } catch (e) {
        this.trackClick("pk_click-ticket-id_fehler");
        // TODO: Error message
        this.setError("HTTP Error");
        console.error(e);
        return e;
      } finally {
        this.loading = false;
      }
      return result;
    },

    updateDigit(index, digit) {
      this.temporaryLotnumber = [
        ...this.temporaryLotnumber.slice(0, index),
        digit,
        ...this.temporaryLotnumber.slice(index + 1)
      ].join("");
    },

    focusPreviousDigitInput(index, clearError = true) {
      const nextIndex = Math.max(index - 1, 0);
      const refName = this.$bp.gt("xs") ? "variableDigit" : "digit";

      if (clearError) {
        this.setError();
      }

      this.$refs[refName][nextIndex] &&
        this.$refs[refName][nextIndex].setFocus();
    },

    focusNextDigitInput(index) {
      const refName = this.$bp.gt("xs") ? "variableDigit" : "digit";
      this.setError();

      this.$refs[refName][
        Math.min(index + 1, this.digitCount - this.digitOffset)
      ].setFocus();
    },

    setError(message = null, headline = null) {
      this.error = message;
      this.errorHeadline = headline;
    },

    setSuccessMessage(message = null) {
      this.successMessage = message;
    },

    async verifyCardLotNumber(digits) {
      let result = { sucess: false };

      try {
        this.loading = true;
        this.setSuccessMessage();
        this.setError();

        result = await verifyCardLotNumber(this.data.product, digits);

        if (!result.success) {
          this.setError(result.status.message);
          return result;
        }

        this.updateLotNumber(result.data.lotNumber, "card");
        this.setSuccessMessage(result.status.message);

        this.clearTemporaryLotnumber();
      } catch (e) {
        // TODO: Error message
        this.setError("HTTP Error");
        console.error(e);
        return e;
      } finally {
        this.loading = false;
      }

      return result;
    },
    clearTemporaryLotnumber() {
      this.temporaryLotnumber = "000000000000";
    },
    cancel() {
      if (this.loading) {
        return;
      }

      this.clearBlurTimeout();
      this.state = State.INITIAL;
      this.clearTemporaryLotnumber();
    },
    focusInput(index) {
      this.intent = null;
      this.clearBlurTimeout();

      this.activeTemporaryDigitIndex = index;
    },
    // We show an error message when an input loses focus
    // and another input does not immediately gain focus again
    async blurInput(index) {
      this.activeTemporaryDigitIndex = null;

      this.blurTimeout = setTimeout(async () => {
        if (this.intent === "cancel" || this.intent === "retry") {
          this.clearBlurTimeout();
          return;
        }

        if (
          index === this.digitCount - this.digitOffset &&
          this.activeTemporaryDigitIndex === null
        ) {
          this.clearBlurTimeout();
          const result = await this.performServiceCallAfterBlur(index);

          if (result.success) {
            this.setError();
          } else {
            setTimeout(() => {
              this.focusPreviousDigitInput(index + 1, false);
            }, 100);
          }

          return;
        }

        if (!this.blurTimeout) {
          return;
        }

        if (this.activeTemporaryDigitIndex === null && !this.successMessage) {
          this.setError(
            this.config.insufficientLotnumberValidationMessage,
            this.config.insufficientLotnumberValidationTitle
          );
        }
      }, 0);
    },
    clearBlurTimeout() {
      if (this.blurTimeout) {
        clearTimeout(this.blurTimeout);
        this.blurTimeout = null;
      }
    },
    updateLotNumber(newLotNumber, type) {
      this.lotnumber = newLotNumber;
      this.data.lotNumber = newLotNumber;
      this.data.lotNumberType = type;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.digit {
  width: 22px;
  height: 30px;
}

.min-width-300 {
  min-width: 300px;
}

.success-message {
  font-size: 12px;
}

.success-message .tw-icon {
  font-size: 24px;
}

.action-button {
  font-size: 12px;
  line-height: 15px;
}

.loading-spinner {
  font-size: 36px;
}

@screen lg {
  .digit {
    font-size: 23px;
    width: 32px;
    height: 44px;
  }

  .digit-input {
    width: 38px;
    height: 44px;
  }

  .action-button {
    font-size: 16px;
    line-height: 19px;
    @apply py-0;
  }
}

.arrow-below {
  position: relative;
}

.arrow-below::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  transform: translateX(-50%) rotate(45deg);
  bottom: -28px;
  left: 50%;
  background-color: #f6f6f6;
  @apply tw-bg-grey-200;
  @apply tw-border-grey-300;
  @apply tw-border-1/2;
}

.arrow-below--tiny {
  position: relative;
}

.arrow-below--tiny::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000;
  bottom: -12px;
}
</style>
