import Vue from "vue";
import App from "./App.vue";
import "./data";
import ButtonRounded from "./components/ButtonRounded.vue";
import Button from "./components/Button.vue";
import Modal from "./components/Modal.vue";
import Tipp from "./components/Tipp.vue";

import "./polyfills";
import "./breakpoint";
import "./interpolate";
import "./formatPrice";
import "./formatDate";
import "./formatAnchors";
import "./tracking";
import "./assets/tailwind.css";
import "./validation";
import { HttpClient } from "./http";

const appEl = document.querySelector("#app");
if (!appEl) {
  throw new Error("Missing #checkout-app element.");
}

export const apiBaseUrl = appEl.dataset.apiBaseUrl;
export const contextItem = appEl.dataset.contextItem;
export const showCancellationAndRuntimeTips = true;

export const httpClient = new HttpClient(apiBaseUrl, {
  headers: {
    "X-Context-Item": contextItem
  }
});

window.lotconfiguratorHttpClient = httpClient;

Vue.config.productionTip = false;

Vue.component("button-rounded", ButtonRounded);
Vue.component("modal", Modal);
Vue.component("btn", Button);
Vue.component("tipp", Tipp);

Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('boldFirstWord', function (value) {
  if (!value) return '';
  value = value.toString();
  const words = value.split(' ');
  if (words.length > 0) {
    words[0] = `<strong>${words[0]}</strong>`;
  }
  return words.join(' ');
});

new Vue({
  render: h => h(App)
}).$mount(appEl);
