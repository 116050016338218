import Vue from "vue";

Vue.mixin({
  methods: {
    track,
    trackClick(eventId) {
      track({ event_id: eventId, etracker_event_action: "click" });
    },
    trackBoosterMultiplier(eventId, productMultiplier) {
      track({
        event_id: eventId,
        etracker_event_action: "click",
        product_multiplier: productMultiplier
      });
    },
    /**
     * Tracking for Onlinebooster drawing levels
     * @param {object} drawingLevel
     * @param {'basis'|'zusatz'} category
     */
    trackDrawingBoosterClick(drawingLevel, category) {
      let eventId;
      const price = drawingLevel.price.toFixed(2).replace(".00", "");

      switch (category) {
        case "basis":
          eventId = `pk_lotterie_5s-ob-konfi_mitte_kachel_h_${price}`;
          break;
        case "zusatz":
          eventId = `pk_lotterie_5s-ob-konfi_mitte_kachel_zc_${price}`;
          break;
      }

      if (!eventId) {
        console.warn("Missing event id, unknown category", category);
        return;
      }

      track({ event_id: eventId, etracker_event_action: "click" });
    }
  }
});

export function track(event) {
  let method = "link";

  if (!window.utag || !window.utag.link || !window.utag.view) {
    return console.warn(
      `utag not available. Cannot track via utag.${method}(): `,
      event
    );
  }

  window.utag[method](event);
}
