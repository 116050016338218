<template>
  <ValidationObserver ref="form" class="lg:tw-w-3/4 tw-mt-2 from" tag="div">
    <InputGroup
      data-qa="Configurator_CustomerDataForm_InputContainer_Salutation"
      :options="salutationOptions"
      rules="required"
      :value="data.recipient.salutation"
      :label="'Anrede'"
      :inline="true"
      input-type="radio"
      @change="data.recipient.salutation = $event.target.value"
    />

    <Dropdown
      class="md:tw-w-1/2 dropdown"
      :label="config.titleFormLabel"
      @change="data.recipient.title = $event.target.value"
    >
      <option :selected="data.recipient.title === ''" value="">
        Bitte wählen
      </option>

      <option
        v-for="title in titleOptions"
        :key="title.value"
        :selected="data.recipient.title === title.value"
        :value="title.value"
      >
        {{ title.name }}
      </option>
    </Dropdown>

    <div class="tw--mx-1/2 md:tw-flex">
      <Input
        class="tw-w-full md:tw-w-1/2 tw-px-1/2"
        rules="required"
        :initial-value="data.recipient.firstname"
        @input="data.recipient.firstname = $event.target.value"
        :label="config.firstnameFormLabel"
      />
      <Input
        class="tw-w-full md:tw-w-1/2 tw-px-1/2"
        rules="required"
        :initial-value="data.recipient.lastname"
        @input="data.recipient.lastname = $event.target.value"
        :label="config.nameFormLabel"
      />
    </div>

    <div class="tw--mx-1/2 md:tw-flex">
      <Input
        class="tw-w-full md:tw-w-3/4 tw-px-1/2"
        rules="required"
        :max-length="40"
        :initial-value="data.recipient.street"
        @input="data.recipient.street = $event.target.value"
        :label="config.streetFormLabel"
      />
      <Input
        class="tw-w-full md:tw-w-1/4 tw-px-1/2"
        :max-length="16"
        :initial-value="data.recipient.housenumber"
        @input="data.recipient.housenumber = $event.target.value"
        :label="config.housenumberFormLabel"
      />
    </div>

    <div class="tw--mx-1/2 md:tw-flex">
      <Input
        class="tw-w-full md:tw-w-1/3 tw-px-1/2"
        :rules="`required|${zipCodeRules.validate}`"
        :maxLength="zipCodeRules.maxlength"
        :minlength="zipCodeRules.minlength"
        @input="data.recipient.zipcode = $event.target.value"
        :label="config.postcodeFormLabel"
        :initial-value="data.recipient.zipcode"
      />
      <Input
        class="tw-w-full md:tw-w-2/3 tw-px-1/2"
        rules="required"
        :initial-value="data.recipient.city"
        @input="data.recipient.city = $event.target.value"
        :label="config.cityFormLabel"
      />
    </div>

    <Dropdown
      class="tw-w-full dropdown"
      :label="config.countryFormLabel"
      rules="required"
      @change="data.recipient.country = $event.target.value"
    >
      <option disabled v-if="!data.recipient.country" selected>
        Bitte wählen
      </option>

      <option
        v-for="country in countryOptions"
        :key="country.value"
        :selected="data.recipient.country === country.value"
        :value="country.value"
      >
        {{ country.name }}
      </option>
    </Dropdown>
  </ValidationObserver>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";
import Input from "./Input";
import InputGroup from "./InputGroup";
import { getZipCodeRules } from "@/validation";
export default {
  components: {
    Input,
    InputGroup,
    Dropdown
  },
  props: {
    config: Object
  },
  computed: {
    salutationOptions() {
      const salutations = this.config.salutations;

      return Object.keys(salutations).map(k => {
        return {
          value: k,
          name: salutations[k]
        };
      });
    },
    titleOptions() {
      const titles = {
        "Dipl. Ing.": "Dipl. Ing.",
        "Dr.": "Dr.",
        "Prof.": "Prof.",
        "Prof. Dr.": "Prof. Dr."
      };

      return Object.keys(titles).map(k => {
        return {
          value: k,
          name: titles[k]
        };
      });
    },
    zipCodeRules() {
      return getZipCodeRules(this.data.recipient.country);
    },
    countryOptions() {
      const countries = this.config.availableShippingCountries;

      return countries.map(({ countryName, countryCode }) => {
        return {
          value: countryCode,
          name: countryName
        };
      });
    }
  },
  methods: {
    async checkValid() {
      return await this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss" scoped></style>
