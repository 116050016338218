<template>
  <div
    class="digit
           tw-flex
           tw-text-center
           tw-items-center
           tw-justify-center
           tw-rounded-md
           tw-mr-1/4
           tw-py-1/4"
    :class="color"
  >
    {{ digit }}
  </div>
</template>

<script>
export default {
  props: {
    digit: String,
    color: { type: String, default: () => "tw-bg-grey-300" }
  }
};
</script>

<style lang="scss" scoped></style>
