/**
 * @typedef {Object} HttpResult
 * @property {boolean} success
 * @property {object} data
 * @property {status} status
 */

import { httpClient } from "@/main";
/**
 *
 * @param {"MGL" | "5SL" | "JL"} product
 * @returns {HttpResult}
 */
export async function getSvlData(product) {
  const payload = {
    lottype: product
  };

  // Convert payload to query string
  const queryString = new URLSearchParams(payload).toString();

  return await httpClient.get(`checkout/order/getsvldata?${queryString}`);
}
