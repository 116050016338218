<template>
  <component
    :is="active ? 'h3' : 'a'"
    :href="fullUrl"
    class="tw-flex tw-text-grey-800 tw-items-center tw-flex-1 tw-py-1 tw-px-1 md:tw-px-2 lg:tw-px-4 tw-h-10 tw-text-s sm:tw-text-base lg:tw-text-xl font-bold"
    :class="{ 'tw-bg-white': active, 'tw-bg-grey-300': !active }"
  >
    {{ headline }}
    <span v-if="active" class="tw-text-2xl tw-icon tw-icon-haken-bold tw-ml-3"></span>
  </component>
</template>

<script>
export default {
  props: {
    active: Boolean,
    url: String,
    headline: String
  },
  computed: {
    fullUrl() {
      const currentParams = new URLSearchParams(window.location.search);
      const baseUrl = new URL(this.url, window.location.origin);
      currentParams.forEach((value, key) => {
        baseUrl.searchParams.set(key, value);
      });
      return baseUrl.toString();
    }
  }
};
</script>
