<template>
  <div
    class="swiper-container"
    ref="container"
    @keydown.capture.tab="handleTab"
    @keydown.capture.space="handleSelect"
    @keydown.capture.enter="handleSelect"
  >
    <div
      class="swiper-wrapper"
      :class="[
        ['xs', 'sm', 'md'].includes(this.$bp.name) && boosterData
          ? 'tw-mb-3'
          : '',
        ['xs', 'sm', 'md'].includes(this.$bp.name) && !boosterData
          ? 'tw-mb-5'
          : ''
      ]"
    >
      <slot />
    </div>

    <button
      v-show="swiperAvailable"
      @click="
        trackClick(
          `pk_lotterie-konfi_mitte_${drawing.levels[0].productMatrixAttribute}_links`
        )
      "
      class="tw-icon
             tw-icon-weiter-left-bold
             tw-flex
             tw-items-center
             tw-content-center
             tw-justify-center
             tw-text-grey-800
             hover:tw-text-black
             tw-h-full
             prev-slide
             tw-z-10
             tw-absolute
             tw-top-0
             tw-left-0
             tw-text-xl
             tw-rounded-r-md"
      :class="
        `tw-bg-white tw-border-${primaryColor}-500 tw-border-2 tw-border-l-0 tw-border-left-color-transparent`
      "
      ref="prevButton"
    ></button>
    <button
      v-show="swiperAvailable"
      @click="
        trackClick(
          `pk_lotterie-konfi_mitte_${drawing.levels[0].productMatrixAttribute}_rechts`
        )
      "
      class="tw-icon
             tw-icon-weiter-left-bold
             tw-flex
             tw-items-center
             tw-justify-center
             tw-text-grey-800
             hover:tw-text-black
             tw-h-full
             next-slide
             tw-z-10
             tw-absolute
             tw-top-0
             tw-right-0
             tw-transform
             tw-rotate-180
             tw-text-xl
             tw-rounded-r-md"
      :class="
        `tw-bg-white tw-border-${primaryColor}-500 tw-border-2 tw-border-l-0 tw-border-left-color-transparent`
      "
      ref="nextButton"
    ></button>

    <div
      v-show="swiperAvailable"
      @click="
        trackClick(
          `pk_lotterie-konfi_mitte_${drawing.levels[0].productMatrixAttribute}_slide`
        )
      "
      class="pagination tw-flex tw-items-center tw-absolute tw-left-1/2 tw-transform tw--translate-x-1/2 tw-bottom-0"
    ></div>
  </div>
</template>

<script>
import "swiper/swiper.min.css";
import "swiper/components/a11y/a11y.min.css";
import "swiper/components/navigation/navigation.min.css";

import { Swiper, Navigation, Pagination, A11y } from "swiper";

Swiper.use([Navigation, Pagination, A11y]);

export default {
  props: {
    activeSlideIndex: Number,
    drawing: Object,
    winningPlanExpanded: Boolean
  },
  data() {
    return {
      swiper: null
    };
  },
  mounted() {
    if (this.$bp.gt("md")) {
      return;
    }

    this.initSwiper();
  },
  computed: {
    swiperAvailable() {
      return this.swiper;
    },
    hasSlides() {
      return this.$slots.default && this.$slots.default.length > 1;
    }
  },
  watch: {
    "$bp.name": function(bp) {
      if (!["xs", "sm", "md"].includes(bp)) {
        if (this.swiper) {
          this.swiper.destroy();
          this.swiper = null;
        }
      } else {
        if (this.swiper) {
          return;
        }

        this.initSwiper();
      }
    },
    activeSlideIndex() {
      if (!this.swiper) {
        return;
      }
      this.swiper.pagination.render();
      this.swiper.slideTo(this.activeSlideIndex);
    }
  },
  methods: {
    initSwiper() {
      if (!this.hasSlides) {
        return;
      }

      let vueInstance = this;
      this.swiper = new Swiper(this.$refs.container, {
        a11y: {
          enabled: true
        },
        navigation: {
          prevEl: ".prev-slide",
          nextEl: ".next-slide",
          disabledClass: "tw-opacity-25 tw-hidden"
        },
        pagination: {
          el: ".pagination",
          type: "bullets",
          clickable: true,
          bulletClass:
            "pagination-bullet tw-bg-grey-300 tw-w-2 tw-h-2 tw-rounded-full tw-inline-block tw-text-white",
          bulletActiveClass: "tw-flex tw-items-center tw-justify-center",
          renderBullet: function(index, className) {
            const bulletClasses = [
              className,
              "hover:tw-bg-grey-600 focus:tw-bg-grey-600"
            ];

            if (vueInstance.activeSlideIndex === index) {
              bulletClasses.push(
                "tw-icon",
                "tw-icon-haken-bold",
                "tw-bg-grey-800"
              );
            } else {
              bulletClasses.push("pagination-bullet--active");
            }

            return `<button class="${bulletClasses.join(" ")}"></class>`;
          }
        },
        slidesPerView: 1.4,
        watchOverflow: true,
        spaceBetween: 16,
        centeredSlides: true,
        initialSlide: this.activeSlideIndex || 0,
        slideClass: "drawings__slider__slide"
      });
    },
    handleTab($event) {
      if (!this.swiper) {
        return;
      }

      if ($event.target.classList.contains("pagination-bullet")) {
        return;
      }

      if ($event.shiftKey) {
        if (this.swiper.isBeginning) {
          return;
        }
        this.swiper.slidePrev(0);
      } else {
        if (this.swiper.isEnd) {
          return;
        }
        this.swiper.slideNext(0);
      }

      $event.preventDefault();
    },
    handleSelect($event) {
      if (!this.swiper) {
        return;
      }

      if ($event.target.classList.contains("pagination-bullet")) {
        return;
      }

      this.$emit("select-level-index", this.swiper.activeIndex);

      $event.preventDefault();
      $event.stopPropagation();
    }
  }
};
</script>

<style lang="scss" scoped>
.prev-slide,
.next-slide {
  width: calc(15% - 16px);
  height: calc(100% - 64px);
}

//:class="winningPlanExpanded ? 'winningPlanExpanded' : '' "
.swiper-container.winningPlanExpanded {
  .prev-slide,
  .next-slide {
    height: calc(100% - 64px);
  }
}

.drawing__slider__slide--single {
  width: 70%;
  flex-grow: 0;
  @apply tw-mx-auto;
}

.drawings__slider__slide {
  @screen xl {
    min-width: 148px;
  }
}

@screen lg {
  .drawing__slider__slide--single {
    width: inherit;
    flex-grow: 0;
    @apply tw-mx-auto;
  }
}

.drawings__slider--booster {
  .prev-slide,
  .next-slide {
    height: 56px;
  }
}

::v-deep .pagination-bullet + .pagination-bullet {
  @apply tw-ml-2;
}

::v-deep .pagination-bullet--active::after {
  content: "";
  @apply tw-w-1 tw-h-1 tw-bg-grey-800 tw-rounded-full;
}
</style>
