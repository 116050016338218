/**
 * @typedef {Object} HttpResult
 * @property {boolean} success
 * @property {object} data
 * @property {status} status
 */

import { httpClient } from "@/main";
/**
 *
 * @param {"MGL" | "5SL" | "JL"} product
 * @param {string} variableDigits
 * @returns {HttpResult}
 */
export async function generateLotnumber(product, variableDigits = null) {
  const payload = {
    product
  };

  if (variableDigits) {
    payload.variableDigits = variableDigits;
  }

  return await httpClient.post("/configurator/lotnumber/generate", payload);
}

/**
 * Fetches available playing start dates for the given product.
 *
 * @param {"MGL" | "5SL" | "JL" | "FST" | "XSL" | "ML"} product - The product type for which to fetch playing start dates.
 *  - `MGL`: Glücks-Los
 *  - `5SL`: 5 Sterne-Los
 *  - `JL`: Jahreslos
 *  - `FST`: 5 Sterne-Los
 *  - `XSL`: Xtra-Superlos
 *  - `ML`: Monatslos
 *
 * @returns {Promise<HttpResult>} - Returns a promise that resolves with an object containing:
 *  - `success` {boolean}: Indicates whether the request was successful.
 *  - `data` {object}: The response data containing available playing start dates.
 *  - `status` {number}: The HTTP status code of the response.
 */
export async function getLotDates(product) {
  return await httpClient.post("/shop/playingstartdateApi/GetLotDates", {
      product
  });
}


/**
 *
 * @param {"MGL" | "5SL" | "JL"} product
 * @param {string} numberToVerify
 * @returns {HttpResult}
 */
export async function verifyCardLotNumber(product, numberToVerify = null) {
  const payload = {
    product
  };

  if (numberToVerify) {
    payload.numberToVerify = numberToVerify;
  }

  return await httpClient.post("/configurator/lotnumber/verify", payload);
}
