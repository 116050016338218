/**
 * @typedef {Object} HttpResult
 * @property {boolean} success
 * @property {object} data
 * @property {status} status
 */

/**
 * {
  "lotConfiguration": {
    "Attribute1": "string",
    "Attribute2": "string",
    "Attribute3": "string",
    "Attribute4": "string",
    "Attribute5": "string",
    "Attribute6": "string",
    "Attribute7": "string"
  },
  "shippingDate": "2021-01-27T11:45:32.316Z",
  "playingDate": "2021-01-27T11:45:32.316Z",
  "lotFormat": "None",
  "lotNumber": "string",
  "product": 0,
  "galleryPosition": 0,
  "page": {},
  "lotNumberType": 0,
  "lotOwner": "GiftLot",
  "lotQuantity": 0,
  "activeSpecialModes": [
    "Undefined"
  ]
}

 * @typedef {Object} ProceedData
 * @property {number} galleryPosition
 * @property {object} lotConfiguration
 * @property {Date} shippingDate
 * @property {Date} playingDate
 * @property {string} lotFormat
 * @property {string} product
 * @property {number} galleryPosition
 * @property {object} page
 * @property {number} lotNumberType
 * @property {"GiftLot" | "VoucherLot"} lotOwner
 * @property {number} lotQuantity
 * @property {Array} activeSpecialModes
 */

/**
 * {
  "lotConfiguration": {
    "Attribute1": "string",
    "Attribute2": "string",
    "Attribute3": "string",
    "Attribute4": "string",
    "Attribute5": "string",
    "Attribute6": "string",
    "Attribute7": "string"
  },
  "shippingDate": "2021-01-27T11:45:32.316Z",
  "playingDate": "2021-01-27T11:45:32.316Z",
  "lotFormat": "None",
  "lotNumber": "string",
  "product": 0,
  "galleryPosition": 0,
  "page": {},
  "lotNumberType": 0,
  "lotOwner": "GiftLot",
  "lotQuantity": 0,
  "specialModes": [
    "Undefined"
  ]
}

 * @typedef {Object} ProceedJahreslosData
 * @property {number} galleryPosition
 * @property {object} lotConfiguration
 * @property {Date} shippingDate
 * @property {Date} playingDate
 * @property {string} lotFormat
 * @property {string} product
 * @property {number} galleryPosition
 * @property {object} page
 * @property {number} lotNumberType
 * @property {"GiftLot" | "VoucherLot"} lotOwner
 * @property {number} lotQuantity
 * @property {Array} specialModes
 * @property {"Customer" | "LotOwner"} jlShippingTarget
 * @property {"LZ1J" | "LZDA"} jlLotDuration
 * @property {object} jlVideoGreeting
 * @property {object} jlAddress
 */

import { httpClient } from "@/main";

/**
 * @param {ProceedData | ProceedJahreslosData} data
 * @returns {HttpResult}
 */
export async function proceedToCheckout(data) {
  return await httpClient.post("/configurator/proceed/checkout", data);
}

/**
 * @param {ProceedData| ProceedJahreslosData} data
 * @returns {HttpResult}
 */
export async function proceedToShoppingcart(data) {
  return await httpClient.post("/configurator/proceed/shoppingcart", data);
}

/**
 * @param {ProceedData| ProceedJahreslosData} data
 * @returns {HttpResult}
 */
export async function proceedToCarddesign(data) {
  return await httpClient.post("/configurator/proceed/carddesign", data);
}

export async function proceedToLotChange(data) {
  return await httpClient.post("/configurator/changelot/proceed", data);
}
