<template>
  <div class="modal" @keydown.esc.capture="open = false">
    <div class="tw-mt-1">
      <slot name="link" :openModal="openModal" />
    </div>

    <FocusLock :disabled="!open">
      <transition
        enter-class="tw-opacity-0"
        enter-active-class="tw-transition-all tw-duration-300"
        leave-active-class="tw-transition-all tw-duration-300"
        leave-to-class="tw-opacity-0"
      >
        <div
          v-if="open"
          class="modal-wrapper tw-flex tw-items-center tw-z-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-25"
          @click.self="open = false"
          ref="scrollable"
        >
          <div
            class="modal-content tw-relative tw-block tw-mx-auto tw-overflow-y-auto tw-bg-white tw-p-4 "
          >
            <button
              @click="open = false"
              class="tw-text-2xl tw-absolute tw-top-0 tw-flex tw-items-center tw-justify-center tw-mt-1/2 tw-mr-1/2 tw-p-1 tw-right-0 tw-rounded-full hover:tw-border hover:tw-text-red-500 hover:tw-border-red-500 focus:tw-border focus:tw-text-red-500 focus:tw-border-red-500"
              :class="{
                'tw-w-5 tw-h-5': !compact,
                'tw-w-4 tw-h-4': compact
              }"
              @focus="onFocusClose"
            >
              <span
                class="tw-block tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2 tw-icon tw-icon-kreuz"
              />
            </button>

            <slot :closeModal="closeModal" :open="open" />
          </div>
        </div>
      </transition>
    </FocusLock>
  </div>
</template>

<script>
import FocusLock from "vue-focus-lock";

export default {
  components: {
    FocusLock
  },
  props: {
    compact: Boolean
  },
  data() {
    return { open: false, firstFocus: true };
  },
  methods: {
    openModal() {
      this.open = true;
    },
    closeModal() {
      this.open = false;
    },
    onFocusClose($event) {
      if (this.firstFocus) {
        $event.target.blur();
      }
      this.firstFocus = false;
    }
  },
  watch: {
    open(newValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.$root.$emit("openModal", this.$refs.scrollable);
        });
      } else {
        this.firstFocus = true;
        this.$root.$emit("closeModal", this.$refs.scrollable);
      }
    }
  }
};
</script>

<style scoped>
.modal-content {
  max-width: 760px;
  width: 90%;
  max-height: 90%;
}
</style>
