<template>
  <component
    @click="$emit('click', $event)"
    @touchstart="$emit('touchstart', $event)"
    @mousedown="$emit('mousedown', $event)"
    @touchend="$emit('touchend', $event)"
    @mouseup="$emit('mouseup', $event)"
    :is="buttonTag"
    :disabled="disabled"
    class="font-bold
          tw-inline-flex
          tw-items-center
          tw-justify-center
          tw-border-2
          tw-duration-300
          tw-transition-colors
          tw-text-base"
    :class="{
      // Base Styles
      'tw-px-2 tw-py-1 tw-w-full': hasContent,
      'button-colored-bg': coloredBg,
      'button-rounded tw-rounded-full': rounded,

      // Primary Styles
      'button-primary': !secondary,

      'tw-text-white tw-bg-red-500 tw-border-solid tw-border-red-500 focus:tw-bg-red-600 focus:tw-border-red-600 hover:tw-bg-red-600 hover:tw-border-red-600 active:tw-bg-red-600':
        !coloredBg && !disabled && !secondary,
      // Primary on colored background
      'tw-bg-white tw-text-red-500 tw-border-solid tw-border-white focus:tw-bg-opacity-80 focus:tw-border-transparent hover:tw-bg-opacity-80 hover:tw-border-transparent active:tw-bg-opacity-80 active:tw-border-transparent':
        coloredBg && !disabled && !secondary,

      // Secondary Styles
      'button-secondary': secondary,
      'tw-text-red-500 tw-bg-transparent tw-border-solid tw-border-red-500 focus:tw-text-white focus:tw-bg-red-500 hover:tw-text-white hover:tw-bg-red-500 active:tw-bg-red-500':
        !coloredBg && !disabled && secondary,

      // Secondary on colored background
      'tw-bg-transparent tw-border-solid tw-border-white tw-text-white focus:tw-bg-white focus:tw-text-red-500 hover:tw-bg-white hover:tw-text-red-500 active:tw-bg-white active:tw-text-red-500':
        coloredBg && !disabled && secondary,

      // Disabled
      'tw-bg-grey-300 tw-border-solid tw-border-grey-300 tw-cursor-not-allowed tw-text-grey-800': disabled,

      // Icon
      'button-icon-only': !hasContent,
      'tw-relative': icon,
      'tw-pr-8': icon && hasContent
    }"
  >
    <slot />
    <span
      v-if="icon"
      class="tw-icon
             tw-absolute
             tw-inline-block
             tw-top-1/2
             tw-transform
             tw--translate-y-1/2
             tw-duration-150
             tw-transition-all"
      :class="[icon, iconClasses, { 'tw-right-2': hasContent }]"
    />
  </component>
</template>

<script>
export default {
  props: {
    buttonTag: {
      type: String,
      default: () => "button"
    },
    secondary: Boolean,
    coloredBg: Boolean,
    disabled: Boolean,
    rounded: Boolean,
    icon: String,
    iconClasses: [String, Array]
  },
  computed: {
    hasContent() {
      return !!this.$slots.default;
    }
  }
};
</script>

<style scoped lang="scss">
a.button-primary,
a.button-secondary {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.button-primary {
  min-height: 44px;
  line-height: 22px;
  &:hover {
    @apply tw-text-white;
  }
}

.button-icon-only {
  height: 44px;
  min-width: 44px;
}

[disabled]:active {
  animation: shake 0.3s;
}

@screen md {
  .button-primary {
    min-width: 160px;
  }

  .button-icon-only {
    min-width: 44px;
  }
}

.tw-icon {
  font-size: 22px;
  height: 24px;
  width: 24px;
}

.tw-icon:before {
  top: 1px;
  @apply tw-relative;
}

.tw-icon:after {
  content: "";
  @apply tw-transition-all tw-duration-150 tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-rounded-full tw-border-2;
}

.button-primary .tw-icon:after {
  @apply tw-border-white;
}

.button-primary.button-colored-bg .tw-icon:after {
  @apply tw-border-red-500;
}

.button-secondary .tw-icon:after {
  @apply tw-border-red-500;
}

.button-secondary.button-colored-bg .tw-icon:after {
  @apply tw-border-white;
}

.button-icon-only[disabled] .tw-icon:after {
  @apply tw-border-grey-800;
}

.button-icon-only:hover .tw-icon:after,
.button-secondary:hover .tw-icon:after,
.button-primary:hover .tw-icon:after {
  @apply tw-origin-center;
  transform: scale(1.1666);
}

.button-secondary:hover .tw-icon:after {
  @apply tw-border-white;
}

.button-secondary:hover.button-colored-bg .tw-icon:after {
  @apply tw-border-red-500;
}

.button-icon-only:hover.button-secondary .tw-icon:after,
.button-icon-only:hover.button-primary .tw-icon:after {
  @apply tw-transform-none;
}

.button-icon-only:hover .tw-icon {
  margin-left: 6px;
}

.button-rounded:hover .tw-icon {
  @apply tw-ml-0;
}

.button-icon-only.button-rounded:hover .tw-icon:after,
.button-secondary.button-rounded:hover .tw-icon:after,
.button-primary.button-rounded:hover .tw-icon:after {
  @apply tw-origin-center;
  transform: scale(1.1666);
}

.button-icon-only:hover[disabled] .tw-icon {
  @apply tw-ml-0;
}

.button-icon-only:hover[disabled] .tw-icon:after {
  @apply tw-transform-none;
}

* {
  @apply tw-align-middle;
}

@keyframes shake {
  0% {
    transform: translate3d(0, 0, 0);
  }
  16% {
    transform: translate3d(-2px, 0, 0);
  }
  32% {
    transform: translate3d(2px, 0, 0);
  }
  48% {
    transform: translate3d(-2px, 0, 0);
  }
  64% {
    transform: translate3d(2px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
</style>
