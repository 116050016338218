import Vue from "vue";
import {
  LotType,
  ShippingTarget,
  PlayingDateUnspecified,
  LotDuration,
} from "@/constants";

//
// Static Data
//
const WinningCategoryLevelHideIndex = 3;
const data = JSON.parse(document.getElementById("data").innerText);

const jahreslosHeader =
  data.categoryAreaContent.find(
    (category) => category.type === "LotTypeSelectionHeader"
  ) || {};

// Remove jahreslos header because it is inserted manually
data.categoryAreaContent = data.categoryAreaContent.filter(
  (content) => content.type !== "LotTypeSelectionHeader"
);

const lotFormatSelector =
  data.categoryAreaContent.find(
    (category) => category.type === "LotFormatSelector"
  ) || {};

const lotTypeSelector =
  data.categoryAreaContent.find(
    (category) => category.type === "LotTypeSelector"
  ) || {};

const lotDurationSelector =
  data.categoryAreaContent.find(
    (category) => category.type === "LotDurationSelector"
  ) || {};

const videoGreetingSelector = data.categoryAreaContent.find(
  (category) => category.type === "VideoGreeting"
);

const dynamicProps = {
  // set by:
  // - LotnumberPicker on lot number change
  // - App after loading edit mode data
  lotNumber: "",
  lotNumberType: "random",
  // set by App on lot format change
  lotFormat:
    lotFormatSelector.preselection || (data.product === "JL" ? null : "Pdf"),
  lotType: lotTypeSelector.preselection || data.lotOwner,
  lotDuration: lotDurationSelector.preselection || data.lotRuntime || "LZ1J",
  // Set by PlayingDateSelector when mounted
  availablePlayingDates: [],
  userSelectedPlayingDate: null,
  // Configured recipient
  recipient: { country: "D" },
  lotShippingTarget: "Customer",
  defaultShippingDate: data.shippingDate,
  invalidShippingDates: [],
  minShippingDate: null,
  maxShippingDate: null,
  videoGreeting: null,
};

// Shuffle videos if necessary
if (videoGreetingSelector && videoGreetingSelector.randomizeVideoSequence) {
  shuffleArray(videoGreetingSelector.videos);
}

Object.assign(data, dynamicProps);

Vue.mixin({
  data() {
    return {
      data,
      WinningCategoryLevelHideIndex
    };
  },
  computed: {
    primaryColor: () => {
      switch (data.product) {
        case "MGL":
          return "green";
        case "FST":
          return "darkRed";
        case "JL":
          return "lightBlue";
      }

      return "grey";
    },
    bestsellerData() {
      return (
        this.data.specialModeContent &&
        this.data.specialModeContent.find(
          (specialMode) => specialMode.type === "Bestseller"
        )
      );
    },
    boosterData() {
      return (
        this.data.specialModeContent &&
        this.data.specialModeContent.find(
          (specialMode) => specialMode.type === "FstOnlineBooster"
        )
      );
    },
    changeLotData() {
      return (
        this.data.specialModeContent &&
        this.data.specialModeContent.find(
          (specialMode) => specialMode.type === "ChangeLot"
        )
      );
    },
    // Jahreslos specific data mapping
    jahreslosData() {
      if (this.data.product !== "JL") {
        return null;
      }

      return {
        header: jahreslosHeader,
      };
    },
    LotType() {
      return LotType;
    },
    PlayingDateUnspecified() {
      return PlayingDateUnspecified;
    },
    LotDuration() {
      return LotDuration;
    },
    targetDependentShippingDate() {
      if (!this.jahreslosData) {
        return this.data.shippingDate;
      }

      if (this.data.lotShippingTarget === ShippingTarget.Customer) {
        return this.data.defaultShippingDate;
      }

      return this.data.shippingDate;
    },
  },
  methods: {
    getConfiguration() {
      const lotConfiguration = {
        Attribute1: "",
        Attribute2: "",
        Attribute3: "",
        Attribute4: "",
        Attribute5: "",
        Attribute6: "",
        Attribute7: "",
      };
      const specialModes = (this.data.specialModeContent || []).map(
        (specialMode) => {
          return specialMode.type;
        }
      );

      this.data.categoryAreaContent.forEach((category) => {
        if (category.type !== "WinningCategory") {
          return;
        }

        category.levels.forEach((level) => {
          if (!level.isSelected) {
            return;
          }

          lotConfiguration[level.productMatrixAttribute] =
            level.productMatrixValue;
        });
      });

      let jlConfig = {};

      if (this.data.product === "JL") {
        jlConfig.jlShippingTarget = this.data.lotShippingTarget;
        jlConfig.jlVideoGreeting = this.data.videoGreeting;

        lotConfiguration["Attribute6"] = this.data.lotDuration;

        if (
          this.data.lotType === LotType.VoucherLot &&
          this.data.lotFormat === "HighQualityPrinted" &&
          this.data.lotShippingTarget === ShippingTarget.LotOwner
        ) {
          jlConfig.jlAddress = this.data.recipient;
        } else if (this.data.lotType === LotType.GiftLot) {
          jlConfig.jlAddress = this.data.recipient;
        }
      } 

      if (this.data.product === "MGL" ||
          this.data.product === "FST"
      ) {
        jlConfig.jlAddress = this.data.recipient;
        jlConfig.jlShippingTarget	= this.data.lotShippingTarget;
      }

      return {
        product: this.data.product,
        orderPosition: this.data.orderPosition,
        shippingDate: this.targetDependentShippingDate,
        playingDate: this.data.playingDate,
        lotFormat: this.data.lotFormat,
        lotNumber: this.data.lotNumber,
        galleryPosition: this.data.galleryPosition,
        page: this.data.currentPage,
        lotNumberType: this.data.lotNumberType,
        lotOwner: this.data.lotType,
        lotQuantity: 1,
        specialModes: specialModes,
        lotConfiguration,
        ...jlConfig,
      };
    },
  },
});

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}
