<template>
  <div class="toggle-switch tw-mt-1 sm:tw-mt-0" :class="`toggle-switch--${color}`">
    <label :for="_uid">
      <input
        :id="_uid"
        type="checkbox"
        class="is-vishidden"
        :checked="value"
        @change="handleInput"
        v-bind="$attrs"
      />

      <span class="toggle-switch__indicator">
        <span class="i"></span>
        <span class="o"></span>
        <span class="switch"></span>
      </span>

      <span class="label">
        <slot name="label"> </slot>

        <template v-if="!$slots.label">
          {{ label }}
        </template>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "green"
    }
  },
  methods: {
    handleInput(event) {
      this.$emit("input", event.target.checked);
    }
  }
};
</script>
<style lang="scss" scoped>
.toggle-switch {
  display: inline-flex;
  min-height: 30px;
}

label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: $font--bold;

  font-size: 12px;
  line-height: 14px;

  .label{
      display: none;
  }
}

.is-vishidden{
    opacity: 0;
}

.toggle-switch__indicator {
  position: relative;
  background: #ababab;
  border-radius: 16px;
  width: 45px;
  height: 24px;
  transition: background-color 0.2s ease-in-out;
  margin-right: 12px;

  .i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    left: 12px;
    height: 10px;
    border: 1px solid $color-white;
  }

  .o {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    right: 8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid $color-white;
  }

  .switch {
    border-radius: 50%;
    background: $color-white;
    position: absolute;
    height: 20px;
    width: 20px;
    margin: 2px;

    transform: none;
    transition: transform 0.2s ease-in-out, height 0.2s ease-in-out,
      margin 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  }
}

//
// States
//
input:checked {
  ~ .toggle-switch__indicator {
    .switch {
      width: 18px;
      height: 18px;
      margin: 3px 2px;
      transform: translateX(22px);
    }

    .toggle-switch--green & {
      background: $ci-color-lightGreen;
    }

    .toggle-switch--orange & {
      background: $ci-color-orange;
    }
  }
}

input:focus {
  ~ .label {
    text-decoration: underline;
  }
}
</style>
