var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"tw-mb-1 tw-text-base lg:tw-text-xl tw-text-grey-800"},[_vm._t("headline")],2),(!_vm.isValid && _vm.errorMessage)?_c('div',{staticClass:"tw-text-red-500 tw-mb-2"},[_c('p',[_vm._v("Dies ist ein Pflichtfeld.")]),_c('p',[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),_c('fieldset',{staticClass:"selectors"},[_c('legend',[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"tw-flex tw-flex-wrap tw--mx-1",class:_vm.videogreetingTip ? 'tw-items-start' : 'tw-items-end'},[_vm._l((_vm.lotProperties),function(lotProperty,index){return [_c('div',{key:lotProperty.value,staticClass:"tw-w-full tw-mt-1 lg:tw-w-1/2 tw-flex tw-flex-col tw-px-1",attrs:{"data-qa":lotProperty.dataQa}},[(index === 0 && _vm.activeTipHtml)?_c('Tipp',{staticClass:"tw--mb-1 tw-z-10 tw-mr-6 property-tip",attrs:{"direction":"bottom-left","bold":false,"emphasize":true}},[_c('div',{staticClass:"tw-text-s",domProps:{"innerHTML":_vm._s(_vm.activeTipHtml)}})]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedLotProperty),expression:"selectedLotProperty"}],staticClass:"tw-sr-only",attrs:{"type":"radio","id":("versandart" + (lotProperty.value)),"name":"versandart","tabindex":"-1"},domProps:{"value":lotProperty.value,"checked":_vm._q(_vm.selectedLotProperty,lotProperty.value)},on:{"change":[function($event){_vm.selectedLotProperty=lotProperty.value},function($event){return _vm.$emit('change', $event.target.value)}]}}),_c('label',{staticClass:"\n              selector-container\n              focus:tw-text-white\n              tw-rounded-md\n              tw-cursor-pointer\n              tw-transition-colors\n              tw-duration-300\n            ",class:[
              ("tw-bg-white tw-border-2 tw-border-" + _vm.primaryColor + "-500"),
              _vm.selectedLotProperty === lotProperty.value
                ? ("tw-bg-" + _vm.primaryColor + "-500 tw-text-white")
                : ("hover:tw-bg-" + _vm.primaryColor + "-400 hover:tw-text-white")
            ],attrs:{"for":("versandart" + (lotProperty.value)),"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.selectedLotProperty = lotProperty.value}}},[_c('div',{staticClass:"\n                selector\n                tw-relative\n                tw-flex\n                tw-flex-col\n                tw-justify-center\n                tw-w-full\n                tw-py-1\n                tw-px-1\n                tw-pl-10\n              "},[_c('select-indicator',{staticClass:"\n                  tw-absolute\n                  tw-ml-2\n                  tw-left-0\n                  tw-top-1/2\n                  tw-transform\n                  tw--translate-y-1/2\n                ",attrs:{"selected":_vm.selectedLotProperty === lotProperty.value}}),_c('strong',[_vm._v(_vm._s(lotProperty.headline))]),_c('span',{staticClass:"tw--mt-1/4 tw-text-s"},[_vm._v(_vm._s(lotProperty.subline))])],1),(
                _vm.videogreetingTip && lotProperty.value === _vm.LotType.VoucherLot
              )?_c('span',{staticClass:"\n                tw-block\n                tw-text-s\n                tw-px-2\n                tw-py-1\n                tw-border-t-2\n                tw-border-dashed\n\n              ",class:[
                lotProperty.value !== _vm.LotType.VoucherLot
                  ? ("tw-border-" + _vm.primaryColor + "-500")
                  : "tw-border-white"
              ],domProps:{"innerHTML":_vm._s(_vm.videogreetingTip)}}):_vm._e()])],1)]})],2)]),_vm._t("modal-link",null,{"lotProperties":_vm.lotProperties},_vm.modal),_vm._t("additional")],2)}
var staticRenderFns = []

export { render, staticRenderFns }