var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.buttonTag,{tag:"component",staticClass:"font-bold\n        tw-inline-flex\n        tw-items-center\n        tw-justify-center\n        tw-border-2\n        tw-duration-300\n        tw-transition-colors\n        tw-text-base",class:{
    // Base Styles
    'tw-px-2 tw-py-1 tw-w-full': _vm.hasContent,
    'button-colored-bg': _vm.coloredBg,
    'button-rounded tw-rounded-full': _vm.rounded,

    // Primary Styles
    'button-primary': !_vm.secondary,

    'tw-text-white tw-bg-red-500 tw-border-solid tw-border-red-500 focus:tw-bg-red-600 focus:tw-border-red-600 hover:tw-bg-red-600 hover:tw-border-red-600 active:tw-bg-red-600':
      !_vm.coloredBg && !_vm.disabled && !_vm.secondary,
    // Primary on colored background
    'tw-bg-white tw-text-red-500 tw-border-solid tw-border-white focus:tw-bg-opacity-80 focus:tw-border-transparent hover:tw-bg-opacity-80 hover:tw-border-transparent active:tw-bg-opacity-80 active:tw-border-transparent':
      _vm.coloredBg && !_vm.disabled && !_vm.secondary,

    // Secondary Styles
    'button-secondary': _vm.secondary,
    'tw-text-red-500 tw-bg-transparent tw-border-solid tw-border-red-500 focus:tw-text-white focus:tw-bg-red-500 hover:tw-text-white hover:tw-bg-red-500 active:tw-bg-red-500':
      !_vm.coloredBg && !_vm.disabled && _vm.secondary,

    // Secondary on colored background
    'tw-bg-transparent tw-border-solid tw-border-white tw-text-white focus:tw-bg-white focus:tw-text-red-500 hover:tw-bg-white hover:tw-text-red-500 active:tw-bg-white active:tw-text-red-500':
      _vm.coloredBg && !_vm.disabled && _vm.secondary,

    // Disabled
    'tw-bg-grey-300 tw-border-solid tw-border-grey-300 tw-cursor-not-allowed tw-text-grey-800': _vm.disabled,

    // Icon
    'button-icon-only': !_vm.hasContent,
    'tw-relative': _vm.icon,
    'tw-pr-8': _vm.icon && _vm.hasContent
  },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)},"touchstart":function($event){return _vm.$emit('touchstart', $event)},"mousedown":function($event){return _vm.$emit('mousedown', $event)},"touchend":function($event){return _vm.$emit('touchend', $event)},"mouseup":function($event){return _vm.$emit('mouseup', $event)}}},[_vm._t("default"),(_vm.icon)?_c('span',{staticClass:"tw-icon\n           tw-absolute\n           tw-inline-block\n           tw-top-1/2\n           tw-transform\n           tw--translate-y-1/2\n           tw-duration-150\n           tw-transition-all",class:[_vm.icon, _vm.iconClasses, { 'tw-right-2': _vm.hasContent }]}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }