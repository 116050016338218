<template>
  <ValidationProvider
    slim
    :name="name"
    :immediate="immediate"
    :rules="rules"
    :mode="validationMode"
    :bails="!validationGroup"
    v-slot:default="{
      valid,
      invalid,
      validated,
      errors
    }"
  >
    <div
      class="tw-pt-1 tw-flex tw-relative tw-flex-col tw-text-grey-800"
      :data-qa="dataQa"
      :class="{
        'input--focus': hasFocus,
        'input--filled': value,
        'input--disabled': disabled,
        'input--valid': validated && valid,
        'input--error': validated && invalid && !validationGroup,
        'input--is-warning': errorAsWarning
      }"
    >
      <label
        :for="_uid"
        class="tw-text-sm"
        :class="{ 'font-bold': hasFocus }"
        :title="title"
      >
        <span>{{ label }}{{ asterisk }}</span>
        <span
          class="tw-ml-1 font-regular text--s"
          v-if="maxLength && showMaxLength"
          >{{ charsLeft }} Zeichen übrig</span
        >
      </label>

      <!-- VeeValidate does not work with <component /> so we have to use this ugly if-else construct -->

      <textarea
        v-if="tag === 'textarea'"
        class="tw-mt-1/2 tw-px-2 tw-py-1 tw-outline-none font-bold tw-text-base tw-bg-white tw-rounded-none"
        :id="_uid"
        v-model="value"
        :type="type"
        :maxlength="maxLength"
        v-on="$listeners"
        v-bind="$attrs"
        @focus="focus()"
        @blur="blur()"
        :title="title"
        :aria-invalid="validated && invalid"
        :aria-describedby="validated && invalid && `err_${_uid}`"
        :disabled="disabled"
        :class="{
          'tw-border': !hasFocus || (!validated && valid),
          'tw-border-2': hasFocus || (validated && invalid),
          'tw-border-grey-800': valid,
          'tw-border-form-error': validated && invalid
        }"
      />
      <input
        v-else
        class="tw-h-input tw-mt-1/2 tw-px-2 tw-outline-none font-bold tw-text-base"
        :id="_uid"
        v-model="value"
        :type="type"
        :maxlength="maxLength"
        v-on="$listeners"
        v-bind="$attrs"
        @focus="focus()"
        @blur="blur()"
        :title="title"
        :aria-invalid="validated && invalid"
        :aria-describedby="validated && invalid && `err_${_uid}`"
        :disabled="disabled"
        :class="{
          'tw-border': !hasFocus || (!validated && valid),
          'tw-border-2': hasFocus || (validated && invalid),
          'tw-border-grey-800': valid,
          'tw-border-form-error': validated && invalid
        }"
      />
      <InputError
        :error-as-warning="errorAsWarning"
        :errors="errors"
        :id="`err_${_uid}`"
        :show="validated"
      />
    </div>
  </ValidationProvider>
</template>

<script>
import InputError from "./InputError";

export default {
  components: { InputError },
  inheritAttrs: false,
  props: {
    dataQa: String,
    mode: String,
    label: String,
    rules: [Object, String],
    initialValue: [String, Number],
    immediate: Boolean,
    errorAsWarning: Boolean,
    name: String,
    validationGroup: String,
    type: String,
    title: String,
    disabled: Boolean,
    tag: {
      default: () => "input"
    },
    maxLength: {
      type: Number,
      default: undefined
    },
    showMaxLength: Boolean
  },
  data() {
    return {
      hasFocus: false,
      value: this.$props.initialValue
    };
  },
  watch: {
    initialValue: {
      immediate: true,
      handler(newValue) {
        this.value = newValue;
      }
    }
  },
  computed: {
    validationMode() {
      if (this.mode) {
        return this.mode;
      }

      if (this.validationGroup) {
        return "aggressive";
      }

      return "eager";
    },
    asterisk() {
      return this.rules && this.rules.indexOf("required") > -1 ? "*" : "";
    },
    charsLeft() {
      if (!this.value) {
        return this.maxLength;
      }

      return this.maxLength - this.value.length;
    }
  },
  methods: {
    focus() {
      this.hasFocus = true;
    },
    blur() {
      this.hasFocus = false;
    }
  }
};
</script>

<style scoped></style>
