<template>
  <div
    class="tw-z-20 tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-text-center tw-pt-3"
  >
    <div
      class="tw-bg-white tw-opacity-80 tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full"
    ></div>

    <div class="loader tw-inline-block tw-relative">
      <div :class="`spinner tw-border-transparent tw-border-t-${color}`"></div>
      <div :class="`spinner tw-border-transparent tw-border-t-${color}`"></div>
      <div :class="`spinner tw-border-transparent tw-border-t-${color}`"></div>
      <div :class="`spinner tw-border-transparent tw-border-t-${color}`"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: () => "white"
    }
  }
};
</script>

<style scoped>
.loader {
  width: 1em;
  height: 1em;
}
.loader .spinner {
  @apply tw-box-border tw-block tw-absolute tw-rounded-full tw-border-solid;
  width: 0.9em;
  height: 0.9em;
  margin: 0.1em;
  border-width: 0.1em;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.loader .spinner:nth-child(1) {
  animation-delay: -0.45s;
}
.loader .spinner:nth-child(2) {
  animation-delay: -0.3s;
}
.loader .spinner:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
