<template>
  <div
    class="tw-w-full tw-py-1 tw-text-white tw-flex tw-justify-center"
    :class="[backgroundColor]"
    v-if="visible"
  >
    <countdown-slider>
      <div
        v-if="date !== '0001-01-01T00:00:00'"
        class="countdown__slide swiper-slide"
      >
        <p class="font-bold">
          <span v-if="prefixText">
            {{ prefixText }}
          </span>
          <span v-if="days.visible">
            <span class="number">{{ days.value }}&nbsp;</span>
            <span class="format">{{ wordString.day }}&nbsp;</span>
          </span>
          <span v-if="hours.visible">
            <span class="number">{{ hours.value }}&nbsp;</span>
            <span class="format">{{ wordString.hours }}&nbsp;</span>
          </span>
          <span v-if="minutes.visible">
            <span class="number">{{ minutes.value }}&nbsp;</span>
            <span class="format">{{ wordString.minutes }}&nbsp;</span>
          </span>
          <span v-if="seconds.visible">
            <span class="number">{{ seconds.value }}&nbsp;</span>
            <span class="format">{{ wordString.seconds }}&nbsp;</span>
          </span>
          <span v-if="postfixText">
            {{ postfixText }}
          </span>
        </p>
      </div>
      <div v-if="slideText" class="countdown__slide swiper-slide">
        <p v-html="slideText"></p>
      </div>
    </countdown-slider>
  </div>
</template>
<script>
import "../dst-correction.js";
import CountdownSlider from "./CountdownSlider";

export default {
  name: "Countdown",
  components: { CountdownSlider },
  props: {
    date: String,
    prefixText: String,
    postfixText: String,
    pluralDayText: String,
    backgroundColor: String,
    slideText: String
  },
  data: function() {
    return {
      timer: "",
      visible: this.$props.date && this.$props.slideText ? true : false,
      wordString: {
        day: this.pluralDayText,
        hours: "Std.",
        minutes: "Min.",
        seconds: "Sek."
      },
      start: "",
      end: "",
      interval: "",
      days: {
        type: "day",
        value: "--",
        visible: false
      },
      minutes: {
        type: "minute",
        value: "--",
        visible: false
      },
      hours: {
        type: "hour",
        value: "--",
        visible: false
      },
      seconds: {
        type: "second",
        value: "--",
        visible: false
      }
    };
  },
  beforeMount: function() {},
  mounted: function() {
    // this.wordString = JSON.parse(this.conf);
    if (this.date !== "0001-01-01T00:00:00") {
      this.start = new Date();
      this.end = new Date(this.date);

      // Update the count down every 1 second
      this.timerCount(this.end);

      this.interval = setInterval(() => {
        this.timerCount(this.end);
      }, 1000);
    }
  },
  beforeUpdate: function() {},
  methods: {
    timerCount: function(end) {
      // Get todays date and time
      var now = new Date();

      if (now.isDst() && !this.end.isDst()) {
        now.setHours(now.getHours() + 1);
      }

      if (end.getTime() <= now.getTime()) {
        clearInterval(this.interval);
        this.$emit("countExpired");
      }

      var remainTime = end - now;

      if (remainTime < 0) {
        clearInterval(this.interval);
        this.visible = false;
        return;
      } else {
        this.visible = true;
        this.calcTime(remainTime);
      }
      this.updateVisibility();
    },
    calcTime: function(dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days.value = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours.value = Math.floor(
        (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes.value = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds.value = Math.floor((dist % (1000 * 60)) / 1000);
    },
    updateVisibility: function() {
      if (this.days.value >= 1) {
        this.days.visible = true;
        this.hours.visible = true;
        this.minutes.visible = false;
        this.seconds.visible = false;
      }
      if (this.days.value >= 1 && this.hours.value < 1) {
        this.days.visible = true;
        this.hours.visible = true;
        this.minutes.visible = false;
        this.seconds.visible = false;
      }
      if (this.days.value === 1) {
        this.wordString.day = "Tag";
      }
      if (this.days.value < 1 && this.hours.value >= 1) {
        this.days.visible = false;
        this.hours.visible = true;
        this.minutes.visible = true;
      }
      if (this.days.value < 1 && this.hours.value < 1) {
        this.days.visible = false;
        this.hours.visible = false;
        this.minutes.visible = true;
        this.seconds.visible = true;
      }
    }
  }
};
</script>

<style lang="scss">
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
