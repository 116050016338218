<template>
  <div>
    <h2 class="tw-mb-1 tw-text-base lg:tw-text-xl tw-text-grey-800">
      <slot name="headline" />
    </h2>
    <div class="tw-text-red-500 tw-mb-2" v-if="!isValid && errorMessage">
      <p>Dies ist ein Pflichtfeld.</p>
      <p>{{ errorMessage }}</p>
    </div>
    <fieldset class="selectors">
      <legend>{{ label }}</legend>
      <div
        class="tw-flex tw-flex-wrap tw--mx-1"
        :class="videogreetingTip ? 'tw-items-start' : 'tw-items-end'"
      >
        <template v-for="(lotProperty, index) in lotProperties">
          <div
            :key="lotProperty.value"
            :data-qa="lotProperty.dataQa"
            class="tw-w-full tw-mt-1 lg:tw-w-1/2 tw-flex tw-flex-col tw-px-1"
          >
            <Tipp
              direction="bottom-left"
              :bold="false"
              :emphasize="true"
              v-if="index === 0 && activeTipHtml"
              class="tw--mb-1 tw-z-10 tw-mr-6 property-tip"
            >
              <div class="tw-text-s" v-html="activeTipHtml"></div>
            </Tipp>
            <input
              type="radio"
              class="tw-sr-only"
              :id="`versandart${lotProperty.value}`"
              name="versandart"
              tabindex="-1"
              :value="lotProperty.value"
              v-model="selectedLotProperty"
              @change="$emit('change', $event.target.value)"
            />

            <label
              :for="`versandart${lotProperty.value}`"
              tabindex="0"
              @keydown.space.enter="selectedLotProperty = lotProperty.value"
              class="
                selector-container
                focus:tw-text-white
                tw-rounded-md
                tw-cursor-pointer
                tw-transition-colors
                tw-duration-300
              "
              :class="[
                `tw-bg-white tw-border-2 tw-border-${primaryColor}-500`,
                selectedLotProperty === lotProperty.value
                  ? `tw-bg-${primaryColor}-500 tw-text-white`
                  : `hover:tw-bg-${primaryColor}-400 hover:tw-text-white`
              ]"
            >
              <div
                class="
                  selector
                  tw-relative
                  tw-flex
                  tw-flex-col
                  tw-justify-center
                  tw-w-full
                  tw-py-1
                  tw-px-1
                  tw-pl-10
                "
              >
                <select-indicator
                  :selected="selectedLotProperty === lotProperty.value"
                  class="
                    tw-absolute
                    tw-ml-2
                    tw-left-0
                    tw-top-1/2
                    tw-transform
                    tw--translate-y-1/2
                  "
                />
                <strong>{{ lotProperty.headline }}</strong>
                <span class="tw--mt-1/4 tw-text-s">{{
                  lotProperty.subline
                }}</span>
              </div>
              <span
                class="
                  tw-block
                  tw-text-s
                  tw-px-2
                  tw-py-1
                  tw-border-t-2
                  tw-border-dashed

                "
                :class="[
                  lotProperty.value !== LotType.VoucherLot
                    ? `tw-border-${primaryColor}-500`
                    : `tw-border-white`
                ]"
                v-if="
                  videogreetingTip && lotProperty.value === LotType.VoucherLot
                "
                v-html="videogreetingTip"
              ></span>
            </label>
          </div>
        </template>
      </div>
    </fieldset>

    <slot name="modal-link" v-bind="modal" :lot-properties="lotProperties" />

    <slot name="additional" />
  </div>
</template>

<script>
import SelectIndicator from "./SelectIndicator.vue";
import Tipp from "./Tipp.vue";

export default {
  components: { SelectIndicator, Tipp },
  name: "lotPropertySelector",
  mounted() {
    //remove initial emit to prevent initial tracking
    //this.$emit("change", this.selectedLotProperty);
  },
  data() {
    return {
      selectedLotProperty: this.$props.selectedProperty,
      activeTipHtml: this.$props.defaultTipHtml,
      isValid: true
    };
  },
  watch: {
    selectedProperty(newValue) {
      this.selectedLotProperty = newValue;
      this.isValid = true;
      this.activeTipHtml = this.activeProperty.tipSelectedHtml
        ? this.activeProperty.tipSelectedHtml
        : this.activeProperty.tipHtml;
    }
  },
  computed: {
    activeProperty() {
      return this.$props.lotProperties.find(
        property => property.value === this.selectedLotProperty
      );
    }
  },
  props: {
    label: String,
    defaultTipHtml: String,
    lotProperties: Array,
    selectedProperty: String,
    modal: Object,
    errorMessage: String,
    videogreetingTip: String
  },
  methods: {
    async checkValid() {
      if (
        this.selectedLotProperty === "" ||
        this.selectedLotProperty === null
      ) {
        this.isValid = false;
      }

      return this.isValid;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selector {
  min-width: 127px;
  min-height: 56px;
  display: flex;
  @apply tw-pl-10 !important;
}

/*Override master form styles*/
.selector-container {
  padding-left: 0 !important;
}

.selector-container:before,
.selector:before,
.selector-container:after,
.selector:after {
  display: none !important;
}

.property-tip::after {
  left: 27px;
}
</style>
