<template>
  <modal v-if="jahreslosData">
    <template #link="{openModal}">
      <a
        href="#"
        class="tw-cursor-pointer no-underline tw-flex tw-items-center hover:tw-text-red-500 focus:tw-text-red-500"
        @click.prevent="openModal"
      >
        <span
          class="tw-icon tw-icon-weiter-left-bold tw-transform tw-rotate-180 tw-text-xl"
        ></span>
        <span class="link-text">{{ linkText }}</span>
      </a>
    </template>
    <template #default="{closeModal}">
      <h2 class="tw-text-2xl tw-grey-800 font-bold">
        {{ headline }}
      </h2>

      <div class="tw-mt-3" v-html="content" />

      <div class="tw-flex tw-mt-2 tw--mx-1">
        <btn
          class="tw-mx-1"
          secondary
          @click="
            $emit('change-greeting-selection', 'accepted');
            closeModal();
          "
        >
          {{ buttonAcceptText }}
        </btn>
        <btn
          class="tw-mx-1"
          secondary
          @click="
            $emit('change-greeting-selection', 'refused');
            closeModal();
          "
        >
          {{ buttonRefuseText }}
        </btn>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  props: {
    linkText: String,
    headline: String,
    content: String,
    buttonAcceptText: String,
    buttonRefuseText: String
  }
};
</script>

<style lang="scss" scoped></style>
