var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"swiper-container",on:{"!keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.handleTab($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.handleSelect($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSelect($event)}]}},[_c('div',{staticClass:"swiper-wrapper",class:[
      ['xs', 'sm', 'md'].includes(this.$bp.name) && _vm.boosterData
        ? 'tw-mb-3'
        : '',
      ['xs', 'sm', 'md'].includes(this.$bp.name) && !_vm.boosterData
        ? 'tw-mb-5'
        : ''
    ]},[_vm._t("default")],2),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.swiperAvailable),expression:"swiperAvailable"}],ref:"prevButton",staticClass:"tw-icon\n           tw-icon-weiter-left-bold\n           tw-flex\n           tw-items-center\n           tw-content-center\n           tw-justify-center\n           tw-text-grey-800\n           hover:tw-text-black\n           tw-h-full\n           prev-slide\n           tw-z-10\n           tw-absolute\n           tw-top-0\n           tw-left-0\n           tw-text-xl\n           tw-rounded-r-md",class:("tw-bg-white tw-border-" + _vm.primaryColor + "-500 tw-border-2 tw-border-l-0 tw-border-left-color-transparent"),on:{"click":function($event){return _vm.trackClick(
        ("pk_lotterie-konfi_mitte_" + (_vm.drawing.levels[0].productMatrixAttribute) + "_links")
      )}}}),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.swiperAvailable),expression:"swiperAvailable"}],ref:"nextButton",staticClass:"tw-icon\n           tw-icon-weiter-left-bold\n           tw-flex\n           tw-items-center\n           tw-justify-center\n           tw-text-grey-800\n           hover:tw-text-black\n           tw-h-full\n           next-slide\n           tw-z-10\n           tw-absolute\n           tw-top-0\n           tw-right-0\n           tw-transform\n           tw-rotate-180\n           tw-text-xl\n           tw-rounded-r-md",class:("tw-bg-white tw-border-" + _vm.primaryColor + "-500 tw-border-2 tw-border-l-0 tw-border-left-color-transparent"),on:{"click":function($event){return _vm.trackClick(
        ("pk_lotterie-konfi_mitte_" + (_vm.drawing.levels[0].productMatrixAttribute) + "_rechts")
      )}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.swiperAvailable),expression:"swiperAvailable"}],staticClass:"pagination tw-flex tw-items-center tw-absolute tw-left-1/2 tw-transform tw--translate-x-1/2 tw-bottom-0",on:{"click":function($event){return _vm.trackClick(
        ("pk_lotterie-konfi_mitte_" + (_vm.drawing.levels[0].productMatrixAttribute) + "_slide")
      )}}})])}
var staticRenderFns = []

export { render, staticRenderFns }