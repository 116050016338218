<template>
  <div>
    <div class="tw-flex md:tw-hidden">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        class="tw-border-b-2 tw-border-grey-300 tw-p-2 tw-flex-grow tw-text-center font-bold focus:tw-outline-none"
        :class="{
          'tw-border-grey-300': !isVisibleTab(index),
          'tw-border-red-500 tw-text-red-500': isVisibleTab(index)
        }"
        @click="activateTab(index)"
      >
        Einsatz {{ $formatPrice(tab.price) }} €
      </button>
    </div>

    <table class="tw-w-full tw-mt-2 tw-table-fixed">
      <thead>
        <tr class="tw-bg-grey-300 tw-border-white">
          <th class="tw-px-2 tw-py-1 tw-text-left">
            Endziffern
          </th>
          <th
            v-for="(tab, index) in tabs"
            :key="index"
            class="tw-px-2 tw-py-1 tw-text-right tw-border-l-2 tw-border-white"
            :class="{
              'tw-hidden md:tw-table-cell': !isVisibleTab(index)
            }"
          >
            <p class="tw-hidden md:tw-block">
              Einsatz {{ $formatPrice(tab.price) }} €
            </p>
            <p class="md:tw-hidden">Gewinn</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="tw-bg-grey-200 tw-border-b-2 tw-border-white"
          v-for="(rank, rankIndex) in tabs[0].ranks"
          :key="rankIndex"
        >
          <td class="tw-px-2 tw-py-1">{{ rank.winningDigits }} Richtige</td>
          <td
            v-for="(tab, tabIndex) in tabs"
            :key="tabIndex"
            class="tw-px-2 tw-py-1 tw-text-right tw-border-l-2 tw-border-white"
            :class="{
              'tw-hidden md:tw-table-cell': !isVisibleTab(tabIndex)
            }"
          >
            <span v-if="rankIndex === 0 && tabIndex === 0"
              >{{ boosterData.basislosLowWinningPlanJackpotText }}
            </span>
            <span v-if="rankIndex === 0 && tabIndex === 1"
              >{{ boosterData.basislosHighWinningPlanJackpotText }}
            </span>
            <span class="font-bold"
              >{{
                $formatPrice(tab.ranks[rankIndex].oneTimeWinningSum)
              }}
              €</span
            >
          </td>
        </tr>
      </tbody>
    </table>

    <div
      class="tw-mt-1 tw-text-s"
      v-html="boosterData.htmlBasislosWinningPlanLegalNotice"
    ></div>
  </div>
</template>

<script>
export default {
  name: "DrawingTable",
  props: {
    tabs: Array,
    preselectedTabIndex: {
      type: Number,
      default: 0
    },
    drawing: Object
  },
  data() {
    return {
      activeTabIndex: 0
    };
  },
  watch: {
    preselectedTabIndex: {
      immediate: true,
      handler(val) {
        this.activeTabIndex = val;
      }
    }
  },
  methods: {
    activateTab(index) {
      this.activeTabIndex = index;
    },
    isVisibleTab(index) {
      return index === this.activeTabIndex;
    }
  }
};
</script>
