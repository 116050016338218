<template>
  <div class="swiper-container" ref="CountdownContainer">
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import "swiper/swiper.min.css";
import "swiper/components/a11y/a11y.min.css";
import "swiper/components/navigation/navigation.min.css";

import { Swiper, Autoplay } from "swiper";

Swiper.use([Autoplay]);

export default {
  name: "CountdownSlider.vue",
  props: {},
  data() {
    return {
      swiper: null
    };
  },
  mounted() {
    this.initSwiper();
  },
  computed: {
    hasSlides() {
      return this.$slots.default && this.$slots.default.length > 1;
    }
  },
  methods: {
    initSwiper() {
      if (!this.hasSlides) {
        return;
      }

      this.swiper = new Swiper(this.$refs.CountdownContainer, {
        autoplay: {
          delay: 6000
        },
        allowTouchMove: false,
        centeredSlides: false,
        centeredSlidesBounds: false,
        slidesPerView: 1
      });
    }
  }
};
</script>

<style></style>
