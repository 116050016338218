import { render, staticRenderFns } from "./ErrorMessage.vue?vue&type=template&id=4d56ccee&scoped=true&"
import script from "./ErrorMessage.vue?vue&type=script&lang=js&"
export * from "./ErrorMessage.vue?vue&type=script&lang=js&"
import style0 from "./ErrorMessage.vue?vue&type=style&index=0&id=4d56ccee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d56ccee",
  null
  
)

export default component.exports