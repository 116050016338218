var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"slim":"","mode":"eager","immediate":_vm.immediate,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('div',{staticClass:"input-group",class:{
      'input--valid': validated && valid
    }},[_c('fieldset',{class:{ 'tw-border tw-border-form-error': validated && invalid }},[_c('legend',{staticClass:"tw-text-sm"},[_c('span',[_vm._v(_vm._s(_vm.label)+_vm._s(_vm.asterisk))])]),_c('div',{staticClass:"inputs tw-pb-1 tw-pl-2 tw--mx-2",class:{
          'tw-flex tw-items-start tw-flex-wrap': _vm.inline,
          'inputs--checkbox': _vm.inputType === 'checkbox'
        }},_vm._l((_vm.options),function(o){return _c('div',{key:o.name,staticClass:"input-group__input tw-flex tw-flex-col tw-mt-1 tw-mr-2"},[_c('label',{staticClass:"label tw-cursor-pointer tw-inline-flex tw-items-center"},[(_vm.inputType === 'radio')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.val),expression:"val"}],staticClass:"tw-sr-only",attrs:{"data-qa":("" + (_vm.$attrs['data-qa']) + (o.value)),"name":o.name,"type":"radio","aria-invalid":validated && invalid,"aria-describedby":validated && invalid && ("err_" + _vm._uid)},domProps:{"value":o.value,"checked":_vm._q(_vm.val,o.value)},on:{"change":function($event){_vm.val=o.value}}},'input',_vm.$attrs,false),_vm.$listeners)):_vm._e(),_c('span',{staticClass:"input__indicator tw-inline-flex tw-items-center tw-justify-center tw-relative tw-rounded-full tw-mr-1 tw-border tw-border-grey-800 tw-bg-white tw-text-white tw-text-xl"},[_c('span',{staticClass:"tw-icon tw-icon-haken-bold"})]),_c('span',{staticClass:"label__text",class:{ 'font-bold': o.value === _vm.val },domProps:{"innerHTML":_vm._s(o.label || o.name)}})])])}),0)]),_c('InputError',{attrs:{"errors":errors,"id":("err_" + _vm._uid),"show":validated}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }