export const LotType = {
  VoucherLot: "VoucherLot",
  GiftLot: "GiftLot",
  PrivateLot: "PrivateLot"
};

export const ShippingTarget = {
  Customer: "Customer",
  LotOwner: "LotOwner"
};

export const LotDuration = {
  OneYear: "LZ1J",
  Unlimited: "LZDA"
};

export const PlayingDateUnspecified = "voucher";
