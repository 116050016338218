import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize
} from "vee-validate";
import {
  required,
  email,
  numeric,
  alpha_num,
  min,
  max
} from "vee-validate/dist/rules";
import de from "vee-validate/dist/locale/de.json";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
window.dayjs = dayjs;
//
// Rules
//
extend("required", required);
extend("numeric", numeric);
extend("alpha_num", alpha_num);
extend("min", min);
extend("max", max);

extend("requiredSelect", {
  ...required,
  validate: value => !!value && value !== "null"
});
extend("addressWarning", {
  validate: () => false,
  message: "_"
});

extend("email", email);

extend("valid_date", {
  validate: value => dayjs(value, "DD.MM.YYYY", true).isValid(),
  message: "Bitte gib ein gültiges Geburtsdatum im Format TT.MM.JJJJ ein."
});

//
// l10n
//

localize({
  de: {
    messages: {
      ...de.messages,
      required: "Dies ist ein Pflichtfeld.",
      requiredSelect: "Dies ist ein Pflichtfeld.",
      email: "Bitte gib eine gültige E-Mail Adresse an.",
      max: "Die Eingabe darf höchstens {length} Zeichen lang sein.",
      min: "Die Eingabe muss mindestens {length} Zeichen lang sein.",
      numeric: "Dieses Feld darf nur Zahlen enthalten.",
      alpha_num: "Dieses Feld darf nur Buchstaben und Zahlen enthalten."
    }
  }
});
localize("de");

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

const alphanumericValidation = {
  minlength: 0,
  maxlength: 10,
  validate: "alpha_num|max:10"
};

const zipCodeCountryMap = {
  D: {
    minlength: 5,
    maxlength: 5,
    validate: "numeric|min:5|max:5"
  },
  GB: alphanumericValidation,
  IRL: alphanumericValidation,
  LV: alphanumericValidation,
  MLT: alphanumericValidation,
  NL: alphanumericValidation,

  default: {
    minlength: 0,
    maxlength: 10,
    validate: "numeric|max:10"
  }
};
export const getZipCodeRules = country =>
  zipCodeCountryMap[country] || zipCodeCountryMap.default;
