<template>
  <li
    tabindex="0"
    class="tw-cursor-pointer tw-text-center tw-rounded-md lg:tw-mx-1 tw-items-center tw-justify-center tw-flex tw-relative tw-mb-2"
    @click.self="$emit('click', video)"
    @keydown.space.prevent="$emit('click', video)"
  >
    <img :src="video.previewImageUrl" @click="$emit('show-video')" />

    <div
      class="play-button tw-pointer-events-none tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-center tw-bg-no-repeat"
    ></div>

    <div @click="$emit('click', video)">
      <SelectIndicator
        class="image-item__indicator tw-absolute tw-bottom-0 tw-ml-0 tw-left-1/2 tw-left-0 tw-transform tw--translate-x-1/2"
        :selected="itemSelected"
      />
    </div>
  </li>
</template>

<script>
import SelectIndicator from "../SelectIndicator.vue";

export default {
  name: "VideoItem",
  components: {
    SelectIndicator
  },
  props: {
    video: Object,
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      itemSelected: this.$props.selected
    };
  },
  watch: {
    selected(newVal) {
      this.itemSelected = newVal;
    }
  }
};
</script>

<style lang="scss" scoped>
.image-item__indicator {
  bottom: -14px;
}
.play-button {
  background-image: url("#{$path-images}/playbutton@2x.png");
  background-size: 50%;
}
</style>
