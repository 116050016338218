<template>
  <modal ref="modal" compact>
    <template #default="{open}">
      <div v-if="open">
        <VideoPlayer
          :video-url="video.videoUrl"
          :poster-image="video.videoPreviewUrl"
        />
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  components: {
    VideoPlayer: () => import("./VideoPlayer")
  },
  data() {
    return {
      video: null
    };
  },
  methods: {
    openModal(video) {
      this.video = video;
      this.$refs.modal.openModal();
    }
  }
};
</script>

<style lang="scss" scoped></style>
