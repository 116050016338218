/**
 * @typedef {Object} HttpResult
 * @property {boolean} success
 * @property {object} data
 * @property {status} status
 */

/**
 * {
  "orderPosition": 0,
  "lotConfiguration": {
    "Attribute1": "string",
    "Attribute2": "string",
    "Attribute3": "string",
    "Attribute4": "string",
    "Attribute5": "string",
    "Attribute6": "string",
    "Attribute7": "string"
  },
  "shippingDate": "2021-01-19T08:02:22.085Z",
  "playingDate": "2021-01-19T08:02:22.085Z",
  "lotFormat": "None",
  "lotNumber": "string",
  "product": 0,
  "galleryPosition": 0,
  "page": {},
  "lotNumberType": 0,
  "lotOwner": "GiftLot",
  "lotQuantity": 0
}

 * @typedef {Object} FinishEditData
 * @property {number} orderPosition
 * @property {object} lotConfiguration
 * @property {Date} shippingDate
 * @property {Date} playingDate
 * @property {string} lotFormat
 * @property {string} product
 * @property {number} galleryPosition
 * @property {object} page
 * @property {number} lotNumberType
 * @property {"GiftLot" | "VoucherLot"} lotOwner
 * @property {number} lotQuantity
 */

/**
 * {
  "orderPosition": 0,
  "lotConfiguration": {
    "Attribute1": "string",
    "Attribute2": "string",
    "Attribute3": "string",
    "Attribute4": "string",
    "Attribute5": "string",
    "Attribute6": "string",
    "Attribute7": "string"
  },
  "shippingDate": "2021-01-19T08:02:22.085Z",
  "playingDate": "2021-01-19T08:02:22.085Z",
  "lotFormat": "None",
  "lotNumber": "string",
  "product": 0,
  "galleryPosition": 0,
  "page": {},
  "lotNumberType": 0,
  "lotOwner": "GiftLot",
  "lotQuantity": 0,
  "jlShippingTarget": "Customer",
  "jlVideoGreeting": {
    "videoTemplateId": "string",
    "senderName": "string",
    "receiverName": "string",
    "greetingText": "string"
  },
  "jlAddress": {
    "salutation": "None",
    "title": "string",
    "firstname": "string",
    "lastname": "string",
    "street": "string",
    "housenumber": "string",
    "zipcode": "string",
    "city": "string",
    "country": "string"
  }
}

 * @typedef {Object} FinishEditJahreslosData
 * @property {number} orderPosition
 * @property {object} lotConfiguration
 * @property {Date} shippingDate
 * @property {Date} playingDate
 * @property {string} lotFormat
 * @property {string} product
 * @property {number} galleryPosition
 * @property {object} page
 * @property {number} lotNumberType
 * @property {"GiftLot" | "VoucherLot"} lotOwner
 * @property {"LZ1J" | "LZDA"} jlLotDuration
 * @property {"Customer" | "LotOwner"} jlShippingTarget
 * @property {object} jlVideoGreeting
 * @property {object} jlAddress
 */

import { httpClient } from "@/main";

/**
 *
 * @param {"MGL" | "5SL" | "JL"} product
 * @param {string} orderPosition
 * @returns {HttpResult}
 */
export async function getData(product, orderPosition) {
  const payload = {
    product,
    orderPosition
  };

  return await httpClient.post("/configurator/edit/data", payload);
}

/**
 *
 * @returns {HttpResult}
 */
export async function cancelEdit() {
  return await httpClient.post("/configurator/edit/cancel");
}

/**
 *
 * @param {FinishEditData | FinishEditJahreslosData} data
 * @returns {HttpResult}
 */
export async function finishEdit(data) {
  return await httpClient.post("/configurator/edit/finish", data);
}
