<template>
  <transition enter-active-class="animate-plop">
    <span
      v-show="visible"
      class="bubble tw-flex tw-z-10 tw-bg-orange-500 tw-text-white font-bold tw-rounded-full"
    >
      <span
        class="tw-flex tw-items-center tw-justify-center tw-rotate-10 tw-p-2 tw-transform"
      >
        <slot />
      </span>
    </span>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.visible = true;
    }, 100);
  }
};
</script>

<style scoped>
.bubble {
  width: 88px;
  height: 88px;
}
</style>
