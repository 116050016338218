<template>
  <div class="tw-self-stretch">
    <div class="tw-h-full" ref="sticky">
      <slot />
    </div>
  </div>
</template>

<script>
import stickybits from "stickybits";
import Vue from "vue";

export default {
  props: {
    disabled: Boolean
  },
  mounted() {
    this.makeSticky();
  },
  watch: {
    disabled(disabled) {
      if (!disabled) {
        this.makeSticky();
      } else {
        this.unstick();
      }
    }
  },
  methods: {
    makeSticky() {
      if (this.disabled) {
        return;
      }
      const opts = {
        scrollEl: this.$refs.sticky[0],
        useStickyClasses: true
      };

      Vue.nextTick(() => {
        this.stickybitsInstance = stickybits(this.$slots.default[0].elm, opts);
      });
    },
    unstick() {
      this.stickybitsInstance.cleanup();
    }
  }
};
</script>

<style scoped>
.js-is-stuck {
  top: 0;
}
</style>
