<template>
  <div class="tw-relative tw-h-full">
    <template v-if="boosterData">
      <!-- MOBILE VIEW WITH ICON -->
      <div class="md:tw-hidden tw-mb-2" v-if="drawingIcon">
        <div class="tw-flex tw-items-center tw-mb-1">
          <div class="tw-mr-2">
            <img
              :src="drawingIcon"
              alt="category type icon"
              class="tw-max-w-none tw-h-8 tw-w-8"
            />
          </div>

          <h2 class="headline-xl tw-mb-0 tw-text-grey-800 tw-pr-u3">
            <span
              class="configurator__box__headline-anchor"
              :id="$formatAnchor(drawing.name)"
            ></span>
            <span v-if="sectionIndex === 1"
              >{{ boosterData.basislosHeadline }}
            </span>
            <span v-if="sectionIndex === 2">{{
              boosterData.secondCategoryHeadline
            }}</span>
          </h2>
        </div>

        <info-text
          v-if="drawing.infoText"
          content-classes="tw-pb-2"
          :content="drawing.infoText"
          :content-mobile="drawing.mobileInfoText"
        ></info-text>

        <div
          class="tw-mb-1"
          v-html="boosterData.htmlBasislosAdditionalText"
          v-if="
            sectionIndex === 1 &&
            boosterData &&
            boosterData.htmlBasislosAdditionalText
          "
        ></div>
        <div
          class="tw-mb-1"
          v-html="boosterData.htmlSecondCategoryAdditionalText"
          v-else-if="
            sectionIndex === 2 &&
            boosterData &&
            boosterData.htmlSecondCategoryAdditionalText
          "
        ></div>
      </div>

      <!-- MOBILE W/O ICON & DESKTOP VIEW -->

      <div class="md:tw-flex" :class="{ 'tw-hidden': drawingIcon }">
        <div v-if="drawingIcon" class="tw-hidden md:tw-block tw-mr-2">
          <img
            :src="drawingIcon"
            alt="category type icon"
            class="tw-max-w-none tw-h-8 tw-w-8"
          />
        </div>

        <div :class="{ 'tw-mt-1 tw-mb-2': drawingIcon }">
          <h2
            class="headline-xl tw-text-grey-800 pr-u3 pr-u0--large-up has-check"
          >
            <span
              class="configurator__box__headline-anchor"
              :id="$formatAnchor(drawing.name)"
            ></span>
            <span v-if="sectionIndex === 1"
              >{{ boosterData.basislosHeadline }}
            </span>
            <span v-if="sectionIndex === 2">{{
              boosterData.secondCategoryHeadline
            }}</span>
          </h2>

          <info-text
            @toggled="
              trackClick(
                sectionIndex === 1
                  ? 'pk_lotterie_5s-ob-konfi_hilfe-info_basislos'
                  : 'pk_lotterie_5s-ob-konfi_hilfe-info_zusatz-chance'
              )
            "
            v-if="drawing.infoText"
            content-classes="tw-pb-2"
            :content="drawing.infoText"
            :content-mobile="drawing.mobileInfoText"
          ></info-text>

          <div
            class="tw-mb-1"
            v-html="boosterData.htmlBasislosAdditionalText"
            v-if="
              sectionIndex === 1 &&
              boosterData &&
              boosterData.htmlBasislosAdditionalText
            "
          ></div>
          <div
            class="tw-mb-1"
            v-html="boosterData.htmlSecondCategoryAdditionalText"
            v-else-if="
              sectionIndex === 2 &&
              boosterData &&
              boosterData.htmlSecondCategoryAdditionalText
            "
          ></div>
        </div>
      </div>
    </template>
    <template v-else>
      <tipp
        v-if="drawing.infoTip && drawing.name !== 'Zusatzspiel' && data.editModeEnabled"
        class="tw-mb-2 tw-mr-5"
        direction="bottom-left"
      >
        <template #default>
          <div v-html="drawing.infoTip.headline"></div>
        </template>
        <template #toggle>
          <div v-html="drawing.infoTip.subline"></div>
        </template>
      </tipp>

      <div class="lg:tw-flex md:tw-flex-wrap tw-items-baseline">
        <h2
          class="
            headline-xl
            tw-text-base
            lg:tw-text-xl
            headline-xl
            tw-mr-4
            lg:tw-mr-1
          "
        >
          <span
            class="configurator__box__headline-anchor"
            :id="$formatAnchor(drawing.name)"
          ></span>
          <span v-if="jahreslosData" class="section-index">{{ sectionIndex + 1 }}.</span>
          {{ drawing.name }}
        </h2>
        <div class="tw-mr-2">
          <p class="tw-mb-1 tw-hidden md:tw-block tw-pr-1">
            {{ drawing.sublineText }}
          </p>
          <p v-if="drawing.asideText" v-html="drawing.asideText" />
        </div>
        <a
          v-if="
            hasRanks && drawing.name !== 'Basislos'
          "
          class="
            hover:tw-no-underline
            tw-flex tw-items-center tw-w-full
            text-bold
            tw-mb-2
          "
          href=""
          @click.prevent="toggleBonusScheme"
          @keydown.space="toggleBonusScheme"
        >
          {{
            winningPlanOpened
              ? data.winningPlan.closeLabel
              : data.winningPlan.openLabel
          }}<span
            :class="
              winningPlanOpened
                ? 'tw-icon-weiter-top-bold'
                : 'tw-icon-weiter-down-bold'
            "
            class="
              tw-align-middle tw-icon
              text-l
              tw-ml-1 tw-rounded-full
              hover:tw-border-red-500 hover:tw-border
            "
          ></span
        ></a>
      </div>
      <div
        class="tw-flex tw-items-center tw-my-2"
        v-if="drawing.name === 'Basislos'"
      >
        <div>
          <span
            class="
              drawings__basislos__icon
              tw-icon tw-icon-lotterie-ziehung tw-border tw-rounded-full
            "
          ></span>
        </div>
        <div class="tw-ml-1">
          <p class="font-bold">
            Chance auf:
            {{ $formatPrice(drawing.levels[0].oneTimeWinningSum, false) }}
            <abbr title="Euro" role="presentation">€&nbsp;</abbr>
          </p>
          <p class="tw-text-s">
            <span
              >für {{ drawing.levels[0].price }}
              <abbr title="Euro" role="presentation">€&nbsp;</abbr></span
            >
            <span>pro Monat</span>
          </p>
        </div>
      </div>

      <info-text
        v-if="drawing.infoText && drawing.name === 'Basislos'"
        content-classes="tw-pb-2"
        :content="drawing.infoText"
        :content-mobile="drawing.mobileInfoText"
        focusButtonClasses="tw-bg-white tw-border-white tw-text-darkRed-500"
        borderColor="tw-border-white"
      ></info-text>
      <info-text
        v-else-if="drawing.infoText"
        content-classes="tw-pb-2"
        :content="drawing.infoText"
        :content-mobile="drawing.mobileInfoText"
      ></info-text>

      <div
        class="
          drawings__basislos__bubble
          tw-hidden
          lg:tw-flex
          tw-bg-white
          tw-absolute
          tw--translate-y-1/2
          tw-border-4
          tw-border-grey-200
          tw-h-10
          tw-rounded-full
          tw-top-1/2
          tw-transform
          tw-w-10
          tw-z-10
          tw-flex
          tw-justify-center
          tw-items-center
        "
        v-if="drawing.name === 'Basislos'"
      >
        <button @click="selectSuperGewinn">
          <span
            class="tw-icon tw-icon-hinzufuegen-bold tw-text-grey-800 tw-text-xl"
          ></span>
        </button>
      </div>

      <a
        v-if="
          hasRanks && drawing.name === 'Basislos'
        "
        class="
          hover:tw-no-underline
          tw-flex tw-items-center tw-w-full
          text-bold
          tw-mb-2
          hover:tw-text-white
        "
        href=""
        @click.prevent="toggleBonusScheme"
        @keydown.space="toggleBonusScheme"
      >
        {{
          winningPlanOpened
            ? data.winningPlan.closeLabel
            : data.winningPlan.openLabel
        }}<span
          :class="
            winningPlanOpened
              ? 'tw-icon-weiter-top-bold'
              : 'tw-icon-weiter-down-bold'
          "
          class="
            tw-align-middle tw-icon
            text-l
            tw-ml-1 tw-rounded-full
            hover:tw-border-red-500 hover:tw-border
          "
        ></span
      ></a>

      <template v-if="winningPlanOpened && drawing.name === 'Basislos'">
        <p
          class="drawing__winningOptions"
          v-for="(rank, index) in drawing.levels[0].ranks.slice().reverse()"
          :key="index"
        >
          <template v-if="rank.oneTimeWinningSum > 0">
            <span class="tw-flex tw-flex-wrap tw-pb-1/2">
              <strong
                >{{ $formatPrice(rank.oneTimeWinningSum, false) }}
                <abbr title="Euro">€</abbr></strong
              >
              <span
                class="tw-ml-1/2"
                v-html="winningPlanTextReplaced(rank)"
              ></span>
            </span>
          </template>
        </p>
      </template>
    </template>

    <template v-if="boosterData">
      <div v-if="sectionIndex === 1">
        <accordion
          :toggleClasses="{ 'md:tw-ml-12': boosterData.basislosIcon }"
          @toggled="onBoosterAccordionToggled"
        >
          <template #button-content="{ focus, hovered }">
            <span
              class="tw-align-middle font-bold"
              :class="{ 'tw-text-red-500': focus || hovered }"
              >{{
                accordionOpen
                  ? boosterData.basislosWinningPlanCloseLabel
                  : boosterData.basislosWinningPlanOpenLabel
              }}</span
            >
            <span
              class="tw-align-middle tw-icon text-l tw-ml-1"
              :class="{
                'tw-text-red-500 tw-border tw-border-red-500 tw-rounded-full':
                  focus || hovered,
                'tw-icon-weiter-top-bold': accordionOpen,
                'tw-icon-weiter-down-bold': !accordionOpen,
              }"
            ></span>
          </template>

          <template #default>
            <drawing-table
              :tabs="drawing.levels"
              :drawing="drawing"
              :preselectedTabIndex="activeDrawingLevelIndex"
            />
          </template>
        </accordion>

        <div class="lg:tw-flex lg:tw--mx-1 tw-mt-2">
          <BoosterDrawingItem
            ref="item"
            v-for="(level, index) in drawing.levels"
            :key="index"
            :bubble="index > 0 ? drawingDesktopTipText : ''"
            :headline="
              index === 0
                ? boosterData.basislosLowHeadline
                : boosterData.basislosHighHeadline
            "
            :hint="index > 0 ? drawingMobileTipText : ''"
            :drawingLevel="level"
            :drawing="drawing"
            :drawingMaxCount="drawingMaxCount"
            :sectionIndex="sectionIndex"
          />
        </div>
      </div>
      <div v-else>
        <drawing-slider
          class="
            drawings__slider drawings__slider--booster
            md:tw-flex
            lg:tw-px-0 lg:tw-pt-0 lg:tw-pb-3
            xl:tw-pb-2
            tw-relative tw--mx-2
            lg:tw--mx-1
          "
          @select-level-index="toggleDrawingLevelByIndex"
          :drawing="drawing"
          :winning-plan-expanded="winningPlanOpened"
          :active-slide-index="activeDrawingLevelIndex"
          :class="[drawing.name === 'Zusatzspiel' ? 'lg:tw-mx-0' : '']"
        >
          <BoosterDrawingItem
            ref="item"
            v-for="(level, index) in drawing.levels"
            :key="index"
            :bubble="index > 0 ? drawingDesktopTipText : ''"
            :headline="index > 0 ? drawingHeadline : ''"
            :hint="index > 0 ? drawingMobileTipText : ''"
            :drawingLevel="level"
            :drawing="drawing"
            :drawingMaxCount="drawingMaxCount"
            :sectionIndex="sectionIndex"
          />
        </drawing-slider>
      </div>
    </template>
    <template v-else-if="drawing.name !== 'Basislos'">
      <div :class="drawing.name === 'Zusatzspiel' ? 'lg:tw-flex' : ''">
        <tipp
          v-if="drawing.infoTip && drawing.name === 'Zusatzspiel'"
          class="
            tw-m-1 tw-mb-2
            lg:tw-mb-0
            tw-order-last
            lg:tw-ml-4
            tw-self-start
            lg:tw-mt-3
          "
          direction="left"
          open
        >
          <template #default>
            <div v-html="drawing.infoTip.headline"></div>
          </template>
          <template #toggle>
            <div
              v-if="drawing.infoTip.subline"
              v-html="drawing.infoTip.subline"
            ></div>
          </template>
        </tipp>

        <drawing-slider
          class="
            drawings__slider
            md:tw-flex
            lg:tw-px-0 lg:tw-pt-0 lg:tw-pb-3
            xl:tw-pb-2
            tw-relative tw--mx-2
            lg:tw--ml-1 lg:tw-mr-0
          "
          @select-level-index="toggleDrawingLevelByIndex"
          :drawing="drawing"
          :active-slide-index="activeDrawingLevelIndex"
          :class="[
            drawing.name === 'Zusatzspiel'
              ? `lg:tw-w-1/${drawingMaxCount} lg:tw-px-1`
              : '',
          ]"
        >
          <DrawingItem
            ref="item"
            v-for="(level, index) in drawing.levels"
            :key="index"
            :drawingLevel="level"
            :drawing="drawing"
            :drawingMaxCount="drawingMaxCount"
            :sectionIndex="sectionIndex"
            :price-text="drawing.priceText"
            :winningPlanExpanded="winningPlanOpened"
            :bestsellerActive="bestsellerActive"
            @toggleBestseller="$emit('toggleBestseller')"
            :class="
              drawing.levels.length === 1
                ? 'drawing__slider__slide--single'
                : `lg:tw-w-1/${drawingMaxCount} lg:tw-flex-shrink`
            "
          />
        </drawing-slider>
      </div>
    </template>
  </div>
</template>

<script>
import InfoText from "../InfoText.vue";
import DrawingItem from "./DrawingItem.vue";
import BoosterDrawingItem from "./BoosterDrawingItem.vue";
import DrawingSlider from "./DrawingSlider";
import DrawingTable from "./DrawingTable.vue";
import Accordion from "../Accordion.vue";

export default {
  name: "Drawing",
  props: {
    drawing: Object,
    drawingMaxCount: Number,
    sectionIndex: Number,
    openBonusSchemeIndex: Number,
    bestsellerActive: Boolean,
    hasBestseller: Object
  },
  components: {
    DrawingItem,
    BoosterDrawingItem,
    DrawingSlider,
    InfoText,
    DrawingTable,
    Accordion,
  },
  created() {
    const breakpointBelowMargin = this.drawing.mobilePreselectionMargin &&
      this.$bp.lte(this.drawing.mobilePreselectionMargin);

    if (breakpointBelowMargin) {
      this.drawing.levels.forEach((level) => level.isSelected = level.isSelectedMobile);
    }
  },
  data() {
    return {
      accordionOpen: false
    };
  },
  methods: {
    winningPlanTextReplaced(rank) {
      return rank.winningPlanText
        .replace("{endziffern}", rank.winningDigits.toString())
        .replace("{gewinner}", rank.numberOfWinners.toString());
    },
    toggleBonusScheme: function () {
      this.$emit("toggleBonusScheme", this.sectionIndex);

      if (this.data.product === "JL") {
        this.winningPlanOpened
          ? this.trackClick(
              `pk_lotterie-jl-konfi_mitte_jahreslos_gewinnmoeglichkeit_ausblenden`
            )
          : this.trackClick(
              `pk_lotterie-jl-konfi_mitte_jahreslos_gewinnmoeglichkeit_einblenden`
            );
      } else {
        this.winningPlanOpened
          ? this.trackClick(
              `pk_lotterie-${
                this.data.product
              }-konfi_mitte_${this.$formatAnchor(
                this.drawing.name
              )}_gewinnmoeglichkeit_ausblenden`
            )
          : this.trackClick(
              `pk_lotterie-${
                this.data.product
              }-konfi_mitte_${this.$formatAnchor(
                this.drawing.name
              )}_gewinnmoeglichkeit_einblenden`
            );
      }
    },
    toggleDrawingLevelByIndex(index) {
      this.$refs.item[index].toggleChecked(this.drawing);
    },
    onBoosterAccordionToggled(open) {
      if (open) {
        this.trackClick(
          "pk_lotterie_5s-ob-konfi_info_alle-gewinne-ausblenden_aufklappen"
        );
      } else {
        this.trackClick(
          "pk_lotterie_5s-ob-konfi_info_alle-gewinne-ausblenden_zuklappen"
        );
      }

      this.accordionOpen = open;
    },
    selectSuperGewinn() {
      const superGewinnDrawing = this.data.categoryAreaContent.find(
        (category) =>
          category.type === "WinningCategory" &&
          category.name === "Super-Gewinn"
      );
      const level = superGewinnDrawing.levels[0];

      level.isSelected = !level.isSelected;
    },
  },
  computed: {
    winningPlanOpened() {
      return this.sectionIndex === this.openBonusSchemeIndex;
    },
    showWinningPlanToggle() {
      if (this.drawing.levels.length < 2) return false;
      else return true;
    },
    hasRanks() {
      return this.drawing.levels[0].ranks.length > 1;
    },
    activeDrawingLevelIndex() {
      return this.drawing.levels.findIndex((l) => l.isSelected);
    },

    drawingIcon() {
      if (!this.boosterData) {
        return false;
      }

      if (this.sectionIndex === 1) {
        return this.boosterData.basislosIcon;
      } else if (this.sectionIndex === 2) {
        return this.boosterData.secondCategoryIcon;
      }

      return false;
    },
    drawingDesktopTipText() {
      if (this.sectionIndex === 1) {
        return this.boosterData.htmlBasislosHighTipTextDesktop;
      } else if (this.sectionIndex === 2) {
        return this.boosterData.htmlBasislosLowTipTextDesktop;
      }

      return "";
    },
    drawingMobileTipText() {
      if (this.sectionIndex === 1) {
        return this.boosterData.htmlBasislosHighTipTextMobile;
      } else if (this.sectionIndex === 2) {
        return this.boosterData.htmlBasislosLowTipTextMobile;
      }

      return "";
    },
    drawingHeadline() {
      if (this.sectionIndex === 1) {
        return this.boosterData.basislosLowHeadline;
      } else if (this.sectionIndex === 2) {
        return this.boosterData.basislosHighHeadline;
      }

      return "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
.drawings__basislos__icon {
  font-size: 54px;
  line-height: 56px;
}

.drawings__basislos__bubble {
  right: -61px;
  width: 48px;
  height: 48px;
}
</style>
