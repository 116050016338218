import URLSearchParams from "@ungap/url-search-params";

export class HttpClient {
  constructor(baseUrl, options = {}) {
    this.baseUrl = baseUrl;

    if (!options.headers) {
      options.headers = {};
    }

    this.options = options;
  }

  /**
   *
   * @param {*} url
   * @param {*} data
   * @param {object} options
   * @param {boolean} options.omitTokenHeader do not add token headers to the request
   */
  post(url, data, options = {}) {
    return this.executeRequest(
      this.buildUrl(url),
      this.config("POST", data, options)
    );
  }

  put(url, data) {
    return this.executeRequest(this.buildUrl(url), this.config("PUT", data));
  }

  get(url) {
    return this.executeRequest(this.buildUrl(url), this.config("GET"));
  }

  delete(url, data, query = null) {
    return this.executeRequest(
      this.buildUrl(url, query),
      this.config("DELETE")
    );
  }

  async executeRequest(url, data) {
    try {
      const response = await fetch(url, data);

      const body = await response.json();

      return {
        success: body.status.code === "OK",
        data: body.data,
        status: body.status
      };
    } catch (e) {
      console.error("http error", e);

      return {
        success: false,
        status: {
          message: "HTTP error"
        },
        error: e
      };
    }
  }

  hasError(result, expectedMessage) {
    if (result === true) {
      return false;
    }

    // A null result is also an error
    return (
      result === null ||
      (result.status.code === "NOK" &&
        result.status.message === expectedMessage)
    );
  }

  config(method, data) {
    const config = {
      method,
      headers: {
        ...this.options.headers,
        "content-type": "application/json"
      }
    };

    if (data) {
      if (method === "POST" || method === "PUT") {
        config.body = JSON.stringify(data);
      }
    }

    return config;
  }

  buildUrl(url, query = null) {
    url = `${this.baseUrl}/${url}`
      .replace("://", "🤣🤣🤣")
      .replace("//", "/")
      .replace("🤣🤣🤣", "://");

    if (query) {
      url = `${url}?${new URLSearchParams(query).toString()}`;
    }

    return url;
  }
}
