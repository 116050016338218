import Vue from "vue";

/**
 * Interpolates {} placeholders
 */

export function interpolate(target, values) {
  const matches = target.match(/\{(.*?)\}/);
  if (!matches || matches.length < 2) {
    return target;
  }

  return matches.slice(1).reduce((target, key) => {
    return target.replace(
      new RegExp(`{${key}}`, "g"),
      values[key] || `{${key}}`
    );
  }, target);
}

Vue.mixin({
  methods: {
    $interpolate: interpolate
  }
});
