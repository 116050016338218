import Vue from "vue";

export function formatDate(data, localstring) {
  const date = new Date(data);
  return date.toLocaleDateString(localstring, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  });
}

Vue.mixin({
  methods: {
    $formatDate: formatDate
  }
});
