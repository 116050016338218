var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"tw-mb-1 tw-text-base lg:tw-text-xl tw-text-grey-800"},[_vm._t("headline")],2),_c('p',{staticClass:"tw-mt-1/2"},[_vm._v(_vm._s(_vm.subline))]),_c('InputGroup',{staticClass:"tw-mt-2",attrs:{"options":_vm.radioOptions,"value":_vm.selectedGreetingVal,"label":_vm.label,"inline":true,"input-type":"radio"},on:{"change":function($event){_vm.selectedGreetingVal = $event.target.value;
      _vm.emitChange();}}}),(_vm.modal)?_c('VideoGreetingModal',{attrs:{"content":_vm.modal.content,"button-accept-text":_vm.modal.activateButtonText,"button-refuse-text":_vm.modal.deactivateButtonText,"link-text":_vm.modal.linkText,"headline":_vm.modal.headline},on:{"change-greeting-selection":function($event){_vm.selectedGreetingVal = $event;
      _vm.emitChange();}}}):_vm._e(),(_vm.selectedGreetingVal === 'accepted')?_c('div',{staticClass:"tw-mt-2"},[_c('h3',{staticClass:"tw-text-base tw-text-grey-800"},[_vm._v(_vm._s(_vm.videoHeadline))]),_c('div',{staticClass:"tw-flex tw-mt-1 lg:tw--mx-1"},[_c('VideoGreetingSlider',{attrs:{"activeSlideIndex":_vm.videos.findIndex(
            function (v) { return _vm.selectedVideo && v.videoId === _vm.selectedVideo.videoId; }
          )}},_vm._l((_vm.videos),function(video){return _c('ImageItem',{key:video.videoId,staticClass:"video-greeting__slider__slide tw-flex-shrink-0 lg:tw-flex-shrink lg:tw-w-1/3",attrs:{"video":video,"selected":_vm.selectedVideo && _vm.selectedVideo.videoId === video.videoId},on:{"click":function($event){return _vm.selectVideo($event)},"show-video":function($event){return _vm.$refs.modal.openModal(video)}}})}),1)],1),_c('VideoGreetingPlaybackModal',{ref:"modal"}),(_vm.selectedVideo)?[_c('h3',{staticClass:"tw-text-base tw-text-grey-800 tw-mt-2"},[_vm._v(" "+_vm._s(_vm.contentHeadline)+" ")]),(_vm.selectedVideo.enableRecipientTextInput)?_c('Input',{staticClass:"tw-w-full md:tw-w-3/4 tw-px-1/2",attrs:{"maxLength":_vm.selectedVideo.recipientTextMaxLength,"show-max-length":"","initialValue":_vm.selectedInputValues.recipient,"label":_vm.selectedVideo.recipientTextLabel},on:{"input":function($event){_vm.selectedInputValues.recipient = $event.target.value;
          _vm.emitContentChange();}}}):_vm._e(),(_vm.selectedVideo.enableGreetingTextInput)?_c('Input',{staticClass:"tw-w-full md:tw-w-3/4 tw-px-1/2",attrs:{"tag":"textarea","rows":"3","maxLength":_vm.selectedVideo.greetingTextMaxLength,"show-max-length":"","initialValue":_vm.selectedInputValues.greeting,"label":_vm.selectedVideo.greetingTextLabel},on:{"input":function($event){_vm.selectedInputValues.greeting = $event.target.value;
          _vm.emitContentChange();}}}):_vm._e(),(_vm.selectedVideo.enableSenderTextInput)?_c('Input',{staticClass:"tw-w-full md:tw-w-3/4 tw-px-1/2",attrs:{"maxLength":_vm.selectedVideo.senderTextMaxLength,"show-max-length":"","initialValue":_vm.selectedInputValues.sender,"label":_vm.selectedVideo.senderTextLabel},on:{"input":function($event){_vm.selectedInputValues.sender = $event.target.value;
          _vm.emitContentChange();}}}):_vm._e()]:_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }