import Vue from "vue";

export function formatAnchor(string) {
  return string.replace(/[^a-zA-Z0-9_-]/g, "");
}

Vue.mixin({
  methods: {
    $formatAnchor: formatAnchor
  }
});
