<template>
  <div
    v-if="drawingIndex === 1"
    class="tw-flex tw-flex-col tw-w-full lg:tw-px-1"
    :class="{
      'drawing-level__margin-top': bubble && !drawingLevel.isSelected
    }"
  >
    <h4
      class="tw-text-m lg:tw-text-base tw-mb-1/2 lg:tw-block"
      :class="{
        'tw-block': bubble && drawingLevel.isSelected,
        'tw-hidden': bubble && !drawingLevel.isSelected
      }"
    >
      {{ headline }}
    </h4>
    <li
      tabindex="0"
      class="
        drawings__slider__slide
        tw-cursor-pointer tw-text-center tw-pl-10
        lg:tw-pl-1
        tw-p-1 tw-rounded-md tw-flex
        lg:tw-flex
        tw-items-center tw-justify-between
        lg:tw-justify-center
        tw-relative tw-mb-1
        lg:tw-mb-5
      "
      :data-qa="dataQa"
      :class="drawingClasses"
      @click="toggleChecked(drawing)"
      @keydown.space.prevent="toggleChecked(drawing)"
    >
      <bubble
        v-if="bubble && !drawingLevel.isSelected"
        class="tw-absolute tw-hidden lg:tw-flex"
      >
        <div v-html="bubble"></div>
      </bubble>
      <div class="drawings__level tw-text-base font-bold tw-cursor-pointer">
        <span
          v-if="drawingLevel.oneTimeWinningSum > 0"
          :data-qa="
            `${this.drawingLevel.productMatrixAttribute}_${this.drawingLevel.productMatrixValue}`
          "
          >{{ $formatPrice(drawingLevel.oneTimeWinningSum, false) }}
          <abbr title="Euro" role="presentation">€</abbr></span
        >
        <span v-if="drawingLevel.monthlyWinningSum > 0" class="tw-block"
          ><span v-if="drawingLevel.oneTimeWinningSum > 0">+</span
          >{{ $formatPrice(drawingLevel.monthlyWinningSum, false) }} mtl.</span
        >
      </div>
      <p
        class="
          drawings__level__price
          lg:tw-absolute
          text-s
          lg:tw-mt-2 lg:font-regular
        "
        :class="{
          'font-bold text-w lg:tw-text-grey-800': drawingLevel.isSelected,
          'lg:tw-text-grey-800': !drawingLevel.isSelected
        }"
      >
        <span
          >{{ $formatPrice(drawingLevel.price) }}
          <abbr title="Euro" role="presentation">€&nbsp;</abbr></span
        >
        <br class="lg:tw-hidden" />
        <span v-if="drawingLevel.paymentIntervalInMonth === 1">mtl.</span>
      </p>

      <div
        class="drawing__tipp lg:tw-hidden tw-absolute tw-left-0 tw-top-0"
        v-if="hint && !drawingLevel.isSelected"
      >
        <tipp direction="bottom-left" emphasize :bold="false">
          <template #default>
            <div v-html="hint"></div>
          </template>
        </tipp>
      </div>
      <select-indicator
        class="
          drawings__level__indicator
          tw-absolute tw-top-1/2
          lg:tw-top-auto
          tw--translate-y-1/2
          lg:tw-translate-y-0
          tw-ml-2
          lg:tw-ml-0 lg:tw-left-1/2
          tw-left-0 tw-transform
          lg:tw--translate-x-1/2
        "
        :selected="drawingLevel.isSelected"
      />
    </li>
  </div>
  <li
    v-else
    tabindex="0"
    class="
      drawings__slider__slide
      tw-cursor-pointer
      lg:tw-mx-1
      tw-text-center tw-pl-10
      lg:tw-pl-1
      tw-p-1 tw-rounded-md tw-flex
      lg:tw-flex
      tw-items-center tw-justify-between
      lg:tw-justify-center
      tw-relative tw-mb-1
      lg:tw-mb-5
    "
    :data-qa="dataQa"
    :class="drawingClasses"
    @click="toggleChecked(drawing)"
    @keydown.space.prevent="toggleChecked(drawing)"
  >
    <bubble
      v-if="bubble && !drawingLevel.isSelected"
      class="tw-absolute tw-hidden lg:tw-flex"
    >
      <div v-html="bubble"></div>
    </bubble>
    <div class="drawings__level tw-text-base font-bold">
      <span v-if="drawingLevel.oneTimeWinningSum > 0"
        >{{ $formatPrice(drawingLevel.oneTimeWinningSum, false) }}
        <abbr title="Euro" role="presentation">€</abbr></span
      >
      <span v-if="drawingLevel.monthlyWinningSum > 0" class="tw-block"
        ><span v-if="drawingLevel.oneTimeWinningSum > 0">+</span
        >{{ $formatPrice(drawingLevel.monthlyWinningSum, false) }} mtl.</span
      >
    </div>
    <p
      class="
        drawings__level__price
        lg:tw-absolute
        text-s
        lg:tw-mt-2 lg:font-regular
        focus:tw-text-white
      "
      :class="{
        'font-bold text-w lg:tw-text-grey-800': drawingLevel.isSelected,
        'lg:tw-text-grey-800 focus:tw-text-white': !drawingLevel.isSelected
      }"
    >
      +
      <span
        >{{ $formatPrice(drawingLevel.price) }}
        <abbr title="Euro" role="presentation">€&nbsp;</abbr></span
      >
      <br class="lg:tw-hidden" />
      <span v-if="drawingLevel.paymentIntervalInMonth === 1">mtl.</span>
    </p>

    <div
      class="drawing__tipp lg:tw-hidden tw-absolute tw-left-0 tw-top-0"
      v-if="hint && !drawingLevel.isSelected"
    >
      <tipp direction="bottom-left" emphasize :bold="false">
        <template #default>
          <div v-html="hint"></div>
        </template>
      </tipp>
    </div>
    <select-indicator
      class="
        drawings__level__indicator
        tw-absolute tw-top-1/2
        lg:tw-top-auto
        tw--translate-y-1/2
        lg:tw-translate-y-0
        tw-ml-2
        lg:tw-ml-0 lg:tw-left-1/2
        tw-left-0 tw-transform
        lg:tw--translate-x-1/2
      "
      :selected="drawingLevel.isSelected"
    />
  </li>
</template>

<script>
import SelectIndicator from "../SelectIndicator";
import Bubble from "../Bubble";

export default {
  name: "BoosterDrawingItem",
  components: { SelectIndicator, Bubble },
  props: {
    drawing: Object,
    drawingLevel: Object,
    drawingIndex: Number,
    bubble: String,
    hint: String,
    headline: String
  },
  computed: {
    drawingClasses: function() {
      return [
        this.drawingLevel.isSelected
          ? `is-checked active:tw-border-${this.primaryColor}-500 tw-bg-${this.primaryColor}-500 tw-text-white hover:tw-bg${this.primaryColor}-500 hover:tw-border-${this.primaryColor}-500 tw-border-2`
          : `tw-bg-white hover:tw-text-grey-800 tw-border-${this.primaryColor}-500 hover:tw-bg-${this.primaryColor}-100 focus:tw-bg-${this.primaryColor}-100 focus:tw-text-grey-800 focus:tw-border-${this.primaryColor}-500 tw-border-2`,
        { "tw-mb-8": this.jahreslosData }
      ];
    },
    dataQa: function() {
      return (
        this.drawingLevel.productMatrixAttribute +
        "_" +
        this.drawingLevel.productMatrixValue
      );
    }
  },
  methods: {
    toggleChecked: function(drawing) {
      if (this.drawingLevel.isSelected && this.drawingIndex !== 1) {
        this.drawingLevel.isSelected = !this.drawingLevel.isSelected;
        return;
      } else {
        this.drawing.levels.forEach(function(key, index) {
          if (drawing.levels[index].isSelected) {
            drawing.levels[index].isSelected = !drawing.levels[index]
              .isSelected;
          }
        });

        if (this.boosterData) {
          this.trackDrawingBoosterClick(
            this.drawingLevel,
            this.drawingIndex === 1 ? "basis" : "zusatz"
          );
        } else {
          this.trackClick(
            `pk_lotterie-konfi_mitte_${this.drawingLevel.productMatrixValue}`
          );
        }
        this.drawingLevel.isSelected = !this.drawingLevel.isSelected;
      }
    }
  }
};
</script>

<style scoped>
.drawings__level__indicator {
  bottom: -14px;
}

.drawings__slider__slide {
  min-height: 56px;
}

.drawing__tipp {
  margin-left: 19px;
  margin-top: -18px;
}

.drawing-level__margin-top {
  margin-top: 25px;
}

@screen lg {
  .drawings__slider__slide {
    min-height: 72px;
  }

  .drawing-level__margin-top {
    margin-top: 0;
  }
}
.bubble {
  width: 88px;
  height: 88px;
  top: -51px;
  right: -10px;
}
</style>
