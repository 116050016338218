import Vue from "vue";

export function formatPrice(price, withFraction = true, autoFraction = false) {
  const fractionDigits = withFraction ? 2 : 0;

  const formatter = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: autoFraction && price % 1 === 0 ? 0 : fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  return formatter.format(price).replace(" €", "");
}

Vue.mixin({
  methods: {
    $formatPrice: formatPrice,
  },
});
