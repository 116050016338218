<template>
  <ValidationProvider
    slim
    mode="eager"
    :immediate="immediate"
    :rules="rules"
    v-slot="{ valid, invalid, validated, errors }"
  >
    <div
      class="input-group"
      :class="{
        'input--valid': validated && valid
      }"
    >
      <fieldset
        :class="{ 'tw-border tw-border-form-error': validated && invalid }"
      >
        <legend class="tw-text-sm">
          <span>{{ label }}{{ asterisk }}</span>
        </legend>
        <div
          class="inputs tw-pb-1 tw-pl-2 tw--mx-2"
          :class="{
            'tw-flex tw-items-start tw-flex-wrap': inline,
            'inputs--checkbox': inputType === 'checkbox'
          }"
        >
          <div
            class="input-group__input tw-flex tw-flex-col tw-mt-1 tw-mr-2"
            v-for="o in options"
            v-bind:key="o.name"
          >
            <label
              class="label tw-cursor-pointer tw-inline-flex tw-items-center"
            >
              <input
                v-if="inputType === 'radio'"
                :data-qa="`${$attrs['data-qa']}${o.value}`"
                v-bind:name="o.name"
                v-bind:value="o.value"
                class="tw-sr-only"
                type="radio"
                v-bind="$attrs"
                v-model="val"
                v-on="$listeners"
                :aria-invalid="validated && invalid"
                :aria-describedby="validated && invalid && `err_${_uid}`"
              />

              <span
                class="input__indicator tw-inline-flex tw-items-center tw-justify-center tw-relative tw-rounded-full tw-mr-1 tw-border tw-border-grey-800 tw-bg-white tw-text-white tw-text-xl"
              >
                <span class="tw-icon tw-icon-haken-bold" />
              </span>
              <span
                class="label__text"
                v-html="o.label || o.name"
                :class="{ 'font-bold': o.value === val }"
              ></span>
            </label>
          </div>
        </div>
      </fieldset>

      <InputError :errors="errors" :id="`err_${_uid}`" :show="validated" />
    </div>
  </ValidationProvider>
</template>

<script>
import InputError from "./InputError";

export default {
  components: { InputError },
  inheritAttrs: false,
  props: {
    label: String,
    rules: [Object, String],
    options: Array,
    inline: Boolean,
    inputType: String,
    selected: { type: String, default: null },
    value: [Array, String, Object],
    immediate: Boolean
  },
  data() {
    return {
      val: this.$props.value
    };
  },
  watch: {
    value(next) {
      this.val = next;
    }
  },
  computed: {
    asterisk() {
      return this.rules && this.label && this.rules.indexOf("required") > -1
        ? "*"
        : "";
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .input__indicator {
    min-width: 28px;
    width: 28px;
    min-height: 28px;
    height: 28px;
  }

  input {
    &:checked {
      + .input__indicator {
        @apply tw-bg-grey-800 tw-text-white;

        &:before {
          transform: scale(1);
        }
      }
    }

    &:focus {
      &:checked {
        ~ .label__text {
          text-decoration: underline;
        }
      }

      + .input__indicator {
        @apply tw-border-4 tw-border-grey-800;
      }
    }
  }
}
</style>
