import Vue from "vue";

Vue.prototype.$bp = {
  // TODO: Inject from tailwind config
  screens: {
    xl: Infinity,
    lg: 1279,
    md: 1023,
    sm: 767,
    xs: 599
  },
  _vm: new Vue({ data: { name: null, width: null } }),
  updateCurrentBreakpoint() {
    let bp = {};

    Object.entries(this.screens).map(([name, width]) => {
      if (window.innerWidth <= width) {
        bp.name = name;
      }
    });

    this._vm.$data.name = bp.name;
    this._vm.$data.width = window.innerWidth;
  },

  bp(name) {
    return this.screens[name];
  },

  gt(name) {
    return this._vm.$data.width > this.screens[name];
  },

  lte(name) {
    return this._vm.$data.width <= this.screens[name];
  },

  get name() {
    return this._vm.$data.name;
  },

  get width() {
    return this._vm.$data.width;
  }
};

window.addEventListener("resize", function() {
  Vue.prototype.$bp.updateCurrentBreakpoint();
});

Vue.prototype.$bp.updateCurrentBreakpoint();
