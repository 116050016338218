var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"slim":"","name":_vm.name,"immediate":_vm.immediate,"rules":_vm.rules,"mode":_vm.validationMode,"bails":!_vm.validationGroup},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var valid = ref.valid;
  var invalid = ref.invalid;
  var validated = ref.validated;
  var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-1 tw-flex tw-relative tw-flex-col tw-text-grey-800",class:{
      'input--focus': _vm.hasFocus,
      'input--filled': _vm.value,
      'input--disabled': _vm.disabled,
      'input--valid': validated && valid,
      'input--error': validated && invalid && !_vm.validationGroup,
      'input--is-warning': _vm.errorAsWarning
    },attrs:{"data-qa":_vm.dataQa}},[_c('label',{staticClass:"tw-text-sm",class:{ 'font-bold': _vm.hasFocus },attrs:{"for":_vm._uid,"title":_vm.title}},[_c('span',[_vm._v(_vm._s(_vm.label)+_vm._s(_vm.asterisk))]),(_vm.maxLength && _vm.showMaxLength)?_c('span',{staticClass:"tw-ml-1 font-regular text--s"},[_vm._v(_vm._s(_vm.charsLeft)+" Zeichen übrig")]):_vm._e()]),(_vm.tag === 'textarea')?_c('textarea',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"tw-mt-1/2 tw-px-2 tw-py-1 tw-outline-none font-bold tw-text-base tw-bg-white tw-rounded-none",class:{
        'tw-border': !_vm.hasFocus || (!validated && valid),
        'tw-border-2': _vm.hasFocus || (validated && invalid),
        'tw-border-grey-800': valid,
        'tw-border-form-error': validated && invalid
      },attrs:{"id":_vm._uid,"type":_vm.type,"maxlength":_vm.maxLength,"title":_vm.title,"aria-invalid":validated && invalid,"aria-describedby":validated && invalid && ("err_" + _vm._uid),"disabled":_vm.disabled},domProps:{"value":(_vm.value)},on:{"focus":function($event){return _vm.focus()},"blur":function($event){return _vm.blur()},"input":function($event){if($event.target.composing){ return; }_vm.value=$event.target.value}}},'textarea',_vm.$attrs,false),_vm.$listeners)):((_vm.type)==='checkbox')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"tw-h-input tw-mt-1/2 tw-px-2 tw-outline-none font-bold tw-text-base",class:{
        'tw-border': !_vm.hasFocus || (!validated && valid),
        'tw-border-2': _vm.hasFocus || (validated && invalid),
        'tw-border-grey-800': valid,
        'tw-border-form-error': validated && invalid
      },attrs:{"id":_vm._uid,"maxlength":_vm.maxLength,"title":_vm.title,"aria-invalid":validated && invalid,"aria-describedby":validated && invalid && ("err_" + _vm._uid),"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"focus":function($event){return _vm.focus()},"blur":function($event){return _vm.blur()},"change":function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}}}},'input',_vm.$attrs,false),_vm.$listeners)):((_vm.type)==='radio')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"tw-h-input tw-mt-1/2 tw-px-2 tw-outline-none font-bold tw-text-base",class:{
        'tw-border': !_vm.hasFocus || (!validated && valid),
        'tw-border-2': _vm.hasFocus || (validated && invalid),
        'tw-border-grey-800': valid,
        'tw-border-form-error': validated && invalid
      },attrs:{"id":_vm._uid,"maxlength":_vm.maxLength,"title":_vm.title,"aria-invalid":validated && invalid,"aria-describedby":validated && invalid && ("err_" + _vm._uid),"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.value,null)},on:{"focus":function($event){return _vm.focus()},"blur":function($event){return _vm.blur()},"change":function($event){_vm.value=null}}},'input',_vm.$attrs,false),_vm.$listeners)):_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"tw-h-input tw-mt-1/2 tw-px-2 tw-outline-none font-bold tw-text-base",class:{
        'tw-border': !_vm.hasFocus || (!validated && valid),
        'tw-border-2': _vm.hasFocus || (validated && invalid),
        'tw-border-grey-800': valid,
        'tw-border-form-error': validated && invalid
      },attrs:{"id":_vm._uid,"maxlength":_vm.maxLength,"title":_vm.title,"aria-invalid":validated && invalid,"aria-describedby":validated && invalid && ("err_" + _vm._uid),"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":(_vm.value)},on:{"focus":function($event){return _vm.focus()},"blur":function($event){return _vm.blur()},"input":function($event){if($event.target.composing){ return; }_vm.value=$event.target.value}}},'input',_vm.$attrs,false),_vm.$listeners)),_c('InputError',{attrs:{"error-as-warning":_vm.errorAsWarning,"errors":errors,"id":("err_" + _vm._uid),"show":validated}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }