<template>
  <div>
    <button
      :class="toggleClasses"
      @click.prevent="toggle"
      @mouseover="hovered = true"
      @mouseout="hovered = false"
      @focus="focus = true"
      @blur="focus = false"
      href="#"
      :aria-controls="id"
      :aria-expanded="isExpanded"
    >
      <slot name="button-content" :focus="focus" :hovered="hovered" />
    </button>

    <section
      class="tw-transition-height tw-duration-150 tw-overflow-hidden"
      ref="content"
      :id="id"
      role="region"
      :aria-hidden="!isExpanded"
      :style="{ maxHeight: height }"
    >
      <slot />
    </section>
  </div>
</template>

<script>
let id = 0;
// Maybe we could use this as a generic a11y-nav // accordion?

export default {
  props: {
    expanded: Boolean,
    toggleClasses: [String, Array, Object]
  },

  created() {
    id++;
  },

  data() {
    return {
      isExpanded: this.$props.expanded,
      maxHeight: 0,
      focus: false,
      hovered: false
    };
  },

  computed: {
    id() {
      return `accordion-${id}`;
    },
    height() {
      return `${this.maxHeight}px`;
    }
  },

  watch: {
    "$bp.name"() {
      this.updateMaxHeight();
    }
  },

  methods: {
    updateMaxHeight() {
      if (this.isExpanded) {
        this.maxHeight = this.$refs.content.scrollHeight;
      }
    },
    toggle() {
      if (this.isExpanded) {
        this.maxHeight = 0;
        this.$emit("close");
      } else {
        this.maxHeight = this.$refs.content.scrollHeight;
        this.$emit("open");
      }

      this.isExpanded = !this.isExpanded;

      this.$emit("toggled", this.isExpanded);
    }
  }
};
</script>

<style lang="scss" scoped></style>
