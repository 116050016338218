var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative"},[_c('span',{staticClass:"tw-hidden",attrs:{"data-qa":"Checkout_Configurator_Value_Lotnumber"}},[_vm._v(_vm._s(_vm.lotnumber))]),_c('h2',{staticClass:"headline-xl tw-text-base lg:tw-text-xl font-bold tw-text-grey-800 pr-u3 pr-u0--large-up"},[(_vm.jahreslosData)?_c('span',{staticClass:"section-index"},[_vm._v(_vm._s(_vm.sectionIndex + 1)+".")]):_vm._e(),_vm._v(" "+_vm._s(_vm.config.headline)+" ")]),(_vm.config.infoText)?_c('info-text',{attrs:{"content-classes":"tw-pb-2","content":_vm.config.infoText,"content-mobile":_vm.config.mobileInfoText},on:{"open":function($event){return _vm.trackClick('pk_click_help_losnummer-zusatzinfo')},"close":function($event){return _vm.trackClick('pk_click_help_losnummer-zusatzinfo')}}}):_vm._e(),_c('div',{staticClass:"tw-flex tw-flex-wrap tw-items-center"},[(_vm.state === _vm.State.EDIT)?_c('h4',{staticClass:"tw-text-s tw-mb-1/2 tw-w-full",domProps:{"innerHTML":_vm._s(_vm.config.inputLabel)}}):_vm._e(),(_vm.state === _vm.State.EDIT_VOUCHER_CODE)?_c('h4',{staticClass:"tw-text-s tw-mb-1/2 tw-hidden sm:tw-block tw-w-full",domProps:{"innerHTML":_vm._s(_vm.config.editAllDigitsLabel)}}):_vm._e(),_c('div',{staticClass:"tw-inline-flex\n             font-bold\n             tw-mb-1",attrs:{"data-qa":"Checkout_Configurator_Value_Digits"}},[(_vm.state !== _vm.State.EDIT_VOUCHER_CODE)?_vm._l((_vm.fixedDigits),function(digit,index){return _c('fixed-digit',{key:("fixed-digit-" + index),staticClass:"tw-bg-grey-300 tw-text-grey-800",attrs:{"data-qa":("Checkout_Configurator_Value_FixedDigits-" + index),"digit":digit}})}):_vm._e(),(_vm.$bp.gt('xs') && _vm.editable)?_vm._l((_vm.variableDigits),function(digit,index){return _c('editable-digit',{key:("edit" + index),ref:"variableDigit",refInFor:true,class:{
            'arrow-below--tiny':
              _vm.state !== _vm.State.EDIT_VOUCHER_CODE &&
              _vm.editable &&
              _vm.activeTemporaryDigitIndex === index
          },attrs:{"data-qa":("Checkout_Configurator_Value_VariableDigits-" + index),"value":digit,"disabled":_vm.loading,"autofocus":index === 0},on:{"focus":function($event){return _vm.focusInput(index)},"blur":function($event){return _vm.blurInput(index)},"input":function($event){return _vm.setTemporaryDigit($event.target.value, index)}}})}):_vm._e(),(!_vm.$bp.gt('xs') || !_vm.editable)?_vm._l((_vm.variableDigits),function(digit,index){return _c('fixed-digit',{key:("variable-digit-" + index),staticClass:"tw-text-white",class:[
            ("tw-bg-" + _vm.primaryColor + "-500"),
            {
              'arrow-below':
                _vm.state !== _vm.State.EDIT_VOUCHER_CODE &&
                _vm.editable &&
                _vm.activeTemporaryDigitIndex === index
            }
          ],attrs:{"data-qa":("Checkout_Configurator_Value_FixedDigits-" + index),"digit":digit}})}):_vm._e()],2),(_vm.loading)?_c('loading-spinner',{staticClass:"tw-hidden sm:tw-inline-block tw--mt-1"}):_vm._e(),(_vm.successMessage)?_c('div',{staticClass:"success-message\n             tw-flex\n             sm:tw-inline-block\n             tw-items-center\n             tw-justify-center\n             tw-w-full\n             sm:tw-w-auto\n             tw-py-5\n             sm:tw-py-0\n             font-bold\n             sm:font-regular\n             tw-bg-green-100\n             sm:tw-bg-transparent\n             xl:tw-ml-1",attrs:{"data-qa":"Checkout_Configurator_Value_Successmessage"}},[_c('span',{staticClass:"tw-icon tw-icon-haken-bold tw-inline-block tw-align-middle"}),_c('span',{staticClass:"tw-ml-1"},[_vm._v(_vm._s(_vm.successMessage))])]):_vm._e(),(_vm.loading)?_c('loading-spinner',{staticClass:"sm:tw-hidden tw--mt-1"}):_vm._e()],1),((_vm.editable && _vm.$bp.gt('xs')) || !_vm.editable)?_c('error-message',{attrs:{"data-qa":"Checkout_Configurator_Value_Errormessage","message":_vm.error,"headline":_vm.errorHeadline}}):_vm._e(),(_vm.editable && !_vm.$bp.gt('xs'))?_c('div',{staticClass:"tw-relative\n           z-1\n           tw-bg-grey-200\n           min-width-300\n           tw-mt-1\n           tw-py-2\n           tw-px-2\n           tw-w-4/5"},[(_vm.state === _vm.State.EDIT)?_c('div',{staticClass:"tw-flex\n            tw-relative\n            tw-justify-between"},_vm._l((_vm.temporaryDigits),function(digit,index){return _c('editable-digit',{key:("mobile-edit" + index),ref:"digit",refInFor:true,class:{
          'arrow-below--tiny': _vm.editable && _vm.activeTemporaryDigitIndex === index
        },attrs:{"data-qa":("Checkout_Configurator_Value_VariableDigits-" + index),"disabled":_vm.loading,"value":digit,"autofocus":index === 0},on:{"focus":function($event){return _vm.focusInput(index)},"blur":function($event){return _vm.blurInput(index)},"delete":function($event){return _vm.clearTemporaryDigit(index)},"input":function($event){return _vm.setTemporaryDigit($event.target.value, index)}}})}),1):_vm._e(),(_vm.state === _vm.State.EDIT_VOUCHER_CODE)?_c('label',{staticClass:"tw-flex tw-flex-col"},[_c('span',{staticClass:"tw-text-s",domProps:{"innerHTML":_vm._s(_vm.config.editAllDigitsLabel)}}),_c('input',{staticClass:"tw-mt-1",attrs:{"disabled":_vm.loading,"data-qa":"Checkout_Configurator_Value_PapierlosMobil","type":"number"},on:{"input":_vm.setTemporaryDigits}})]):_vm._e(),_c('error-message',{attrs:{"message":_vm.error,"headline":_vm.errorHeadline}})],1):_vm._e(),(!_vm.readonly)?_c('div',{staticClass:"tw-flex tw-mt-2 tw-flex-wrap"},[(_vm.state === _vm.State.INITIAL)?[_c('button-rounded',{staticClass:"action-button tw-mr-1",attrs:{"type":"rounded","icon-before":true,"disabled":_vm.loading,"icon":"tw-icon-schreiben","data-qa":"Checkout_Configurator_Link_EditNumber"},on:{"click":function($event){$event.preventDefault();_vm.trackClick('pk_click_ticket-id_edit');
          _vm.setSuccessMessage();
          _vm.setError();
          _vm.state = _vm.State.EDIT_START;}}},[_c('span',{staticClass:"tw-py-1/2",domProps:{"innerHTML":_vm._s(_vm.config.editNumberLinkLabel)}})])]:_vm._e(),(_vm.state === _vm.State.EDIT_START)?[_c('div',{staticClass:"tw-flex tw-flex-no-wrap"},[_c('button-rounded',{staticClass:"action-button tw-mr-1",attrs:{"type":"rounded","data-qa":"Checkout_Configurator_Link_EditNumber","icon-before":true,"disabled":_vm.loading,"icon":"tw-icon-schreiben"},on:{"click":function($event){$event.preventDefault();_vm.trackClick('pk_click_ticket-id_edit');
            _vm.setSuccessMessage();
            _vm.setError();
            _vm.state = _vm.State.EDIT;}}},[_c('span',{staticClass:"tw-py-1/2",domProps:{"innerHTML":_vm._s(_vm.config.changeNumberLinkLabel)}})]),_c('button-rounded',{staticClass:"action-button  tw-mr-1",attrs:{"data-qa":"Checkout_Configurator_Link_RandomNumber","type":"rounded","icon-before":true,"disabled":_vm.loading,"icon":"tw-icon-laden"},on:{"click":function($event){$event.preventDefault();_vm.trackClick('pk_click_ticket-id_new');
            _vm.setSuccessMessage();
            _vm.generateLotnumber();}}},[_c('span',{staticClass:"tw-py-1/2",domProps:{"innerHTML":_vm._s(_vm.config.generateRandomLinkLabel)}})]),(_vm.product === 'MGL')?_c('button-rounded',{staticClass:"action-button",attrs:{"data-qa":"Checkout_Configurator_Link_Loskarte","type":"rounded","icon-before":true,"disabled":_vm.loading,"icon":"tw-icon-lotterie-ziehung"},on:{"click":function($event){$event.preventDefault();_vm.trackClick('pk_click_ticket-id_loskarte');
            _vm.setSuccessMessage();
            _vm.setError();
            _vm.state = _vm.State.EDIT_VOUCHER_CODE;}}},[_c('span',{staticClass:"tw-py-1/2",domProps:{"innerHTML":_vm._s(_vm.config.editAllDigitsLinkLabel)}})]):_vm._e()],1)]:_vm._e(),(_vm.state !== _vm.State.INITIAL)?[(_vm.error && _vm.state === _vm.State.EDIT)?_c('button-rounded',{staticClass:"action-button",attrs:{"data-qa":"Checkout_Configurator_Link_EditAgain","type":"rounded","icon-before":true,"disabled":_vm.loading,"icon":"tw-icon-kreuz"},on:{"mousedown":function($event){_vm.intent = 'retry'},"mouseup":function($event){_vm.intent = null},"touchstart":function($event){_vm.intent = 'retry'},"touchend":function($event){_vm.intent = null},"click":function($event){$event.preventDefault();_vm.setSuccessMessage();
          _vm.setError();
          _vm.clearTemporaryLotnumber();
          _vm.focusNextDigitInput(-1);}}},[_c('span',{staticClass:"tw-py-1/2",domProps:{"innerHTML":_vm._s(_vm.config.tryAnotherNumberLabel)}})]):_vm._e(),_c('div',{staticClass:"tw-w-full tw-mt-1"},[_c('button-rounded',{staticClass:"action-button",attrs:{"data-qa":"Checkout_Configurator_Link_CancelEdit","type":"rounded","icon-before":true,"disabled":_vm.loading,"icon":"tw-icon-kreuz"},on:{"mousedown":function($event){_vm.intent = 'cancel'},"mouseup":function($event){_vm.intent = null},"touchstart":function($event){_vm.intent = 'cancel'},"touchend":function($event){_vm.intent = null},"click":function($event){$event.preventDefault();_vm.trackClick('pk_click_ticket-id_cancel_edit');
            _vm.setSuccessMessage();
            _vm.setError();
            _vm.cancel();}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.config.cancelLabel)}})])],1)]:_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }