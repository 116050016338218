<template>
  <component
    @click="$emit('click', $event)"
    @touchstart="$emit('touchstart', $event)"
    @mousedown="$emit('mousedown', $event)"
    @touchend="$emit('touchend', $event)"
    @mouseup="$emit('mouseup', $event)"
    :is="buttonTag"
    class="button-rounded tw-text-grey-800 tw-inline-flex tw-items-center pad-right tw-rounded-3 tw-border tw-border-grey-800"
    :class="{
      'hover:tw-border-red-500 hover:tw-text-red-500 active:tw-bg-red-500 active:tw-text-white': !disabled,
      'tw-opacity-50 tw-cursor-wait': disabled,
      'tw-pl-1/2': icon,
      'pad-left': !icon
    }"
  >
    <span
      v-if="icon && iconBefore"
      class="tw-icon tw-inline-block"
      :class="[icon, iconClasses]"
    />
    <slot />
    <span
      v-if="icon && iconAfter"
      class="tw-icon tw-inline-block"
      :class="[icon, iconClasses]"
    />
  </component>
</template>

<script>
export default {
  props: {
    buttonTag: {
      type: String,
      default: () => "button"
    },
    disabled: Boolean,
    icon: String,
    iconBefore: Boolean,
    iconAfter: Boolean,
    iconClasses: [String, Array]
  }
};
</script>

<style scoped>
.tw-icon {
  font-size: 30px;
}

.button-rounded {
  min-height: 32px;
}

.pad-left {
  padding-left: 12px;
}

.pad-right {
  padding-right: 12px;
}

* {
  @apply tw-align-middle;
}
</style>
